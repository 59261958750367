import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import { get_Api_request } from "../../helpers/Api.js";
import { headers } from "../../helpers/variables";
import moment from "moment";
import logo from "../../img/logo192.png";
const domainpath = "https://api.deals365days.com";

const BlogSingle = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [singleBlogDetails, setSingleBlogDetails] = useState({});

  useEffect(() => {
    const getSingleProduct = async () => {
      const slug_name = params.id.toLowerCase();
      const url = `${domainpath}/api/blogs/slug/${slug_name}`;
      const response = await get_Api_request(url, headers);
      if (response.status === 200) {
        const product = response.data;
        setSingleBlogDetails(product);
      }
      setLoading(false);
    };

    getSingleProduct();
  }, [params.id]);

  const createMarkup = () => ({ __html: singleBlogDetails.html });

  if (loading) return <p>Loading...</p>;
  function setHTMLContentHeight(htmlContent) {
    // Create a hidden container to render the HTML content
    const hiddenContainer = document.createElement("div");
    hiddenContainer.style.visibility = "hidden";
    hiddenContainer.style.position = "absolute";
    hiddenContainer.style.top = "-9999px";
    hiddenContainer.style.left = "-9999px";
    // Set the HTML content to the hidden container
    hiddenContainer.innerHTML = htmlContent;
    // Append the hidden container to the document body
    document.body.appendChild(hiddenContainer);
    // Get the height of the rendered content
    const contentHeight = hiddenContainer.offsetHeight;
    // Remove the hidden container from the DOM
    document.body.removeChild(hiddenContainer);

    return contentHeight;
  }
  const url = encodeURIComponent(window.location.href);
  const title = encodeURIComponent(document.title);
  return (
    <div>
      <div className="container m-auto lg:w-[90%] from-[#D7E4E4]">
        <Helmet>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#000000" />
          <link rel="icon" href={logo} />
          <meta
            name="title"
            content={
              singleBlogDetails?.meta != null
                ? singleBlogDetails?.meta.meta_title
                : ""
            }
          />
          <title>
            {singleBlogDetails?.meta != null
              ? singleBlogDetails?.meta.meta_title
              : ""}
          </title>

          <meta name="robots" content="index, follow"></meta>
          <meta
            content={
              singleBlogDetails?.meta != null
                ? singleBlogDetails?.meta.meta_description
                : ""
            }
          ></meta>
          <meta
            name="description"
            data-react-helmet="true"
            content={
              singleBlogDetails?.meta != null
                ? singleBlogDetails?.meta.meta_description
                : ""
            }
          />
          <meta
            name="keywords"
            data-react-helmet="true"
            content={
              singleBlogDetails?.meta != null
                ? singleBlogDetails?.meta.meta_keyword
                : ""
            }
          />
          <link rel="apple-touch-icon" href={logo} />
        </Helmet>
        <div className="card_into_linespace">
          {loading ? (
            <div className="flex items-center justify-center w-full h-full">
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
            </div>
          ) : (
            <div className="w-full lg:w-4/5 md:w-3/5 xs:w-3/5 p-4 m-auto">
              <div>
                <div className="single_blog">
                  <div className="blo">
                    <h1>{singleBlogDetails?.title}</h1>
                    <img
                      src={domainpath + singleBlogDetails?.featured_image}
                      alt="Image"
                      className="w-full"
                    />
                  </div>
                  <div className="blo_content">
                    <span dangerouslySetInnerHTML={createMarkup()}></span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="w-full lg:w-4/5 md:w-3/5 xs:w-3/5 p-4 m-auto">
          <div className="col lg:w-[100%]">
            <div className="Comments-icon-section">
              <div className="mb-5">
                <div className="lg:flex  justify-between">
                  <div className="lg:flex">
                    <h1 className=" my-1 pl-2 mx-1">
                      {moment(singleBlogDetails.createdAt).format(
                        "DD MMMM,YYYY"
                      )}
                    </h1>
                    <div className="my-1 flex mx-1">
                      <p>
                        <i className="fa fa-user mx-1"></i>
                      </p>
                      <p className="text-blue-500">AjivaInfotech Team</p>
                    </div>
                  </div>
                  <div className="">
                    <div className="flex font-bold"> Share it</div>
                    <div className="flex my-5">
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-[50px]"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a
                        href={`https://twitter.com/intent/tweet?url=${url}&text=${title}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-[50px]"
                      >
                        <i class="fa-brands fa-x-twitter"></i>
                      </a>
                      <a
                        href={`https://www.instagram.com/?url=${url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-[50px]"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-[50px]"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="lg">
                    <h1 className="font-bold">FOLLOW US</h1>
                    <div className="my-5 flex">
                      <p className="w-[50px]">
                        <a
                          href="https://www.facebook.com/Ajivainfotech/"
                          target="_blank"
                          aria-label="facebook login"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </p>
                      <p className="w-[50px]">
                        <a
                          href="https://twitter.com/ajivainfotech"
                          target="_blank"
                          aria-label="facebook login"
                        >
                          <i class="fa-brands fa-x-twitter"></i>
                        </a>
                      </p>
                      <p className="w-[50px]">
                        <a
                          href="https://www.instagram.com/ajivainfotech/"
                          target="_blank"
                          aria-label="facebook login"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </p>
                      <p className="w-[50px]">
                        <a
                          href="https://in.linkedin.com/company/ajivainfotech"
                          target="_blank"
                          aria-label="NavLinkedin login"
                        >
                          {" "}
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSingle;

// NotFound.js

import React from "react";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

import logo from "../../img/logo192.png";
import page_not_found_logo from "../../aservecse_img/404.png";
const NotFound = () => {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="title"
          content="Empower Your Digital Presence with Expert Website & App Development Services - Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content="Web Solutions Agency,  PHP Developer, Nord.js, React, zapier, Full Stack Developers, Wordpress AMC services, CRM development, App development, Software solutions
"
        />
        <meta
          name="description"
          content="Looking for comprehensive Web Solutions Agency? Our team of skilled PHP Developers specializes in Nord.js, React, and Zapier integrations. We offer expert Full Stack Developers, Wordpress AMC services, and CRM development to meet your business needs "
        />
        <link rel="apple-touch-icon" href={logo} />
        <title>
          Empower Your Digital Presence with Expert Website & App Development
          Services - Ajiva Infotech
        </title>
      </Helmet>
      <div className="min-h-[300px] flex items-center justify-center bg-gray-100">
        <div className="p-8 bg-white shadow-lg rounded-lg ">
          <div>
            <img
              className="m-auto"
              src={page_not_found_logo}
              alt="404"
              height={100}
              width={100}
            />
          </div>
          <h2 className="text-4xl font-bold text-red-500 mb-4">
            Oops! 404 Not Found
          </h2>
          <p className="text-gray-600 mb-8">
            Sorry, the page you are looking for could not be found.
          </p>
          <Link
            to="/"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
          >
            Go back to homepage
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFound;

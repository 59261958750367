import { decryptData } from "./encryption";

// Helper function to get a cookie by name
function getCookie(name) {
  const cookieString = document.cookie;
  const cookies = cookieString.split("; ");

  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null; // Return null if the cookie is not found
}

export const getUserData = () => {
  // Check if the user data is available in cookies
  const encryptedUserDataFromCookie = getCookie("user");
  const accessTokenFromCookie = getCookie("accessToken");
  const refreshTokenFromCookie = getCookie("refreshToken");

  if (
    encryptedUserDataFromCookie &&
    accessTokenFromCookie &&
    refreshTokenFromCookie
  ) {
    // Try to decrypt user data from cookies
    try {
      const userData = decryptData(encryptedUserDataFromCookie);

      // Map your custom user data structure
      const user = {
        id: userData.userInfo?.id || null,
        firstName: userData.userInfo?.first_Name || "",
        lastName: userData.userInfo?.last_Name || "",
        email: userData.userInfo?.email || "",
        role: userData.userInfo?.user_role || "",
        permissions: userData.userInfo?.permissions || {},
        accessToken: accessTokenFromCookie,
        refreshToken: refreshTokenFromCookie,
      };

      // Additional mapping for staff roles
      if (user.role === "staff" && userData.employerid) {
        user.id = userData.employerid;
      }

      return user;
    } catch (error) {
      console.error("Decryption failed for user data in cookies:", error);
      return null;
    }
  }

  // Fallback to localStorage if cookies are not available
  const encryptedUserData = localStorage.getItem("user");
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  if (encryptedUserData && accessToken && refreshToken) {
    try {
      const userData = decryptData(encryptedUserData);

      // Map your custom user data structure
      const user = {
        id: userData.userInfo?.id || null,
        firstName: userData.userInfo?.first_Name || "",
        lastName: userData.userInfo?.last_Name || "",
        email: userData.userInfo?.email || "",
        role: userData.userInfo?.user_role || "",
        permissions: userData.userInfo?.permissions || {},
        accessToken: accessToken,
        refreshToken: refreshToken,
      };

      // Additional mapping for staff roles
      if (user.role === "staff" && userData.employerid) {
        user.id = userData.employerid;
      }

      return user;
    } catch (error) {
      console.error("Decryption failed for localStorage user data:", error);
      return null;
    }
  }

  return null; // Return null if no user data is found
};

export const getUserRole = () => {
  const userData = getUserData();
  console.log(userData, "userData");

  return userData ? userData.role : null;
};

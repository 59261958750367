import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { headers } from "../../helpers/variables";
import moment from "moment";
import blog_bg from "../../img/background_img/Blog.webp";
import { get_Api_request } from "../../helpers/Api.js";
import logo from "../../img/logo192.png";
import blog_bg_1 from "../../img/banner/blog_mob_view_006.jpg";
import Defaultimageblog from "../../img/default123.jpg";
const domainpath = "https://api.deals365days.com";

const Blog = () => {
  const [AllBlogs, setAllBlogs] = useState([]);
  const [singleBlogDetails, setSingleBlogDetails] = useState([]);
  const url = window.location.href;
  function extractHost(url) {
    try {
      const urlObject = new URL(url);
      return urlObject.host;
    } catch (error) {
      console.error(`Invalid URL: ${url}`);
      return null;
    }
  }
  const DomainPath = extractHost(url);
  useEffect(() => {
    async function GetAllBlogs() {
      const url =
        "https://api.deals365days.com/api/blogs/domain/" + "ajivainfotech.com";
      const response = await get_Api_request(url, headers);
      if (response.status == 200) {
        const blogsData = response?.data;
        console.log(blogsData[0]);
        setAllBlogs(blogsData);
        setSingleBlogDetails(blogsData[0]);
      }
    }
    GetAllBlogs();
  }, []);
  function extractFirst30Words(htmlContent) {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(htmlContent, "text/html");
    const textContent = parsedHtml.body.textContent.trim();
    const words = textContent.split(" ");
    const first30Words = words.slice(0, 25);
    return first30Words.join(" ");
  }
  const handleblogRight = (slugname) => {
    const getSingleProduct = async () => {
      const slug_name = slugname;
      const url = `${domainpath}/api/blogs/slug/${slug_name}`;
      const response = await get_Api_request(url, headers);
      if (response.status === 200) {
        const product = response.data;
        setSingleBlogDetails(product);
      }
    };

    getSingleProduct();
  };
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="title"
          content="Latest Digital transformation, Web & Mobile Technology | AjivaInfotech Blog"
        />
        <title>
          Latest Digital transformation, Web & Mobile Technology | AjivaInfotech
          Blog
        </title>
        <meta name="robots" content="index, follow"></meta>
        <meta
          content="Official AjivaInfotech Blogs & News- Discover the latest insights, trends, and best practices in software development, testing, and quality assurance."
          name="description"
        ></meta>
        <meta
          name="description"
          content="Official AjivaInfotech Blogs & News- Discover the latest insights, trends, and best practices in software development, testing, and quality assurance."
        />
        <link rel="apple-touch-icon" href={logo} />
      </Helmet>

      <section className="container-fluid m-auto">
        <div className="w-full ">
          <img
            className="w-full "
            width=""
            height=""
            src={blog_bg}
            alt="blog_bg"
            // className="lg:block md:block hidden"
          />
          <img
            src={blog_bg_1}
            alt="blog_bg"
            className="lg:hidden md:hidden block"
          />
        </div>
      </section>

      <section className="one py-6">
        <div className="container m-auto">
          <div className="w-full lg:flex">
            <div className="lg:w-2/5 my-4">
              <div className="bg-red-600 w-full text-center mx-2">
                <h2 className="text-4xl text-white py-2 px-4">RECENT POST</h2>
              </div>
              {AllBlogs?.map((item) => (
                <div className="card mt-2">
                  <div
                    className="blog_card 
                "
                    onClick={() => handleblogRight(item.slug)}
                  >
                    <div className="w-full py-2 cursor-pointer	hover:scale-125 transition duration-500">
                      <img
                        src={
                          item?.featured_image != "null" && item?.featured_image
                            ? domainpath + item.featured_image
                            : Defaultimageblog
                        }
                        alt="Image"
                        onClick={() =>
                          (window.location.href = `/blog/${item?.slug}`)
                        }
                      />
                    </div>
                    <div className="py-1 pb-3 mx-6">
                      <h2
                        className="text-xl font-bold text-[#393939] cursor-pointer	 hover:text-blue-400 "
                        onClick={() =>
                          (window.location.href = `/blog/${item?.slug}`)
                        }
                      >
                        {item?.title}
                      </h2>
                      <p className="text-sm py-1 text-[#4b4b4b] font-medium lg:hide_content cursor-pointer  hover:underline-offset-4	">
                        {item.html && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: extractFirst30Words(item.html),
                            }}
                          ></p>
                        )}
                      </p>
                      <p
                        onClick={() =>
                          (window.location.href = `/blog/${item?.slug}`)
                        }
                        className="text-red-600 cursor-pointer"
                      >
                        Continue Reading...
                      </p>
                    </div>
                  </div>
                  <div class="border-t-2 border-dotted border-slate-400 pt-4"></div>
                </div>
              ))}
              <div className="text-center bg-gray-900 py-2">
                <h2 className="text-white text-3xl uppercase font-semibold py-2">
                  Stay update with latest blog
                </h2>
                <form>
                  <input
                    type="email"
                    placeholder="Enter Your Email ID"
                    className="w-11/12 m-auto py-3 px-4 my-2"
                  />
                  <button className="text-white w-11/12 m-auto py-3 px-4 bg-red-600 my-2 text-lg font-semibold">
                    SUBSCRIBE
                  </button>
                </form>
              </div>
            </div>
            <div className="lg:w-3/5">
              {singleBlogDetails ? (
                <div className="px-6 my-4">
                  <div className="border py-2 px-2 m-auto">
                    <a>
                      <img
                        src={domainpath + singleBlogDetails.featured_image}
                        alt="blog_img_big_1"
                        width=""
                        height=""
                        className="m-auto w-full"
                        onClick={() =>
                          (window.location.href = `/blog/${singleBlogDetails?.slug}`)
                        }
                      />
                    </a>
                  </div>
                  <div id="style-3" className="py-2 blog_overflow_content">
                    <h3 className="text-2xl font-bold my-2 mx-1 text-[#393939]">
                      {singleBlogDetails.title}
                    </h3>
                    <div className="flex my-4">
                      <p className="text-base font-semibold text-gray-700 ">
                        <i class="fa-solid fa-user px-1"></i>
                        <span>AjivaInfotech Team</span>
                      </p>
                      <p className="text-base font-semibold text-gray-700 mx-2">
                        <i class="fa-regular fa-clock px-1"></i>
                        <span>
                          {" "}
                          {moment(singleBlogDetails.createdAt).format(
                            "YYYY-MM-DD"
                          )}
                        </span>
                      </p>
                    </div>
                    <div class="border-t-2 border-dotted border-slate-400 my-4"></div>
                    <div className="">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: singleBlogDetails.html,
                        }}
                      ></span>
                      <button
                        className="bg-red-600 text-white py-3 px-4 my-2 text-base"
                        onClick={() =>
                          (window.location.href = `/blog/${singleBlogDetails?.slug}`)
                        }
                      >
                        Continue Reading...
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Blog;

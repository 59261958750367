import React, { useState } from "react";
import { parseString } from "xml2js";
import Helmet from "react-helmet";
import logo from "../../../img/logo192.png";
const XMLToJSONConverter = () => {
  const [xmlInput, setXmlInput] = useState("");
  const [jsonOutput, setJsonOutput] = useState(null);
  const [error, setError] = useState(null);

  const handleConvert = () => {
    parseString(
      xmlInput,
      { explicitArray: false, mergeAttrs: true },
      (err, result) => {
        if (err) {
          setError(`Error parsing XML: ${err.message}`);
          setJsonOutput(null);
        } else {
          setJsonOutput(result);
          setError(null);
        }
      }
    );
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="" />
        <meta
          name="title"
          content="Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" coding validation tools, programming tools, code checker, code error finding tool, code beautify tool, code scanning tool"
        />
        <meta
          name="description"
          content=" Discover top coding validation tools to ensure error-free, high-quality code. Learn how tools like HTML Preview, Minyfiy to UnMinyfiy, Json Validator, Validate CSS, String Replace, Webhook Test can streamline your development workflow and enforce coding standards. "
        />
        {/* <link rel="apple-touch-icon" href={logo} /> */}
        <title>
          Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech
        </title>
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
        <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-3xl">
          <h1 className="text-2xl font-bold mb-4 text-center">
            XML to JSON Converter
          </h1>
          <textarea
            rows="10"
            className="w-full p-2 border border-gray-300 rounded-md mb-4"
            placeholder="Enter XML here"
            value={xmlInput}
            onChange={(e) => setXmlInput(e.target.value)}
          ></textarea>
          <button
            onClick={handleConvert}
            className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition duration-300"
          >
            Convert
          </button>
          {error && <p className="text-red-500 mt-4">{error}</p>}
          {jsonOutput && (
            <div className="mt-6">
              <h2 className="text-xl font-semibold mb-2">JSON Output</h2>
              <pre className="bg-gray-200 p-4 rounded-md overflow-auto">
                {JSON.stringify(jsonOutput, null, 2)}
              </pre>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default XMLToJSONConverter;

import React, { useEffect, useState } from "react";
import contact_bg from "../../img/background_img/Contact-Us.webp";
import { NavLink } from "react-router-dom";
import Aservices from "./Aservices";

const View_More_Services = () => {
  const [percentage, setPercentage] = useState(0);
  const [percentage1, setPercentage1] = useState(0);
  const [percentage2, setPercentage2] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      if (percentage < 92) {
        setPercentage(percentage + 1);
      }
      if (percentage1 < 286) {
        setPercentage1(percentage1 + 1);
      }
      if (percentage2 < 672) {
        setPercentage2(percentage2 + 1);
      }
    }, 50);
  }, [percentage, percentage1, percentage2]);

  return (
    <div className="cocontainer m-auton">
      <section>
        <div className="">
          <img src={contact_bg} />
        </div>
        <div className="container m-auto ">
          <div className="">
            <div className="opp">
              <div className="bang">
                <h1 className="">
                  view_
                  <span className="text-red-700	 ">our services</span>
                </h1>
              </div>
              <div className=" text-center text-3xl  font-semibold	">
                <NavLink to="/" className="text-blue-600	text-xl pr-2">
                  Home
                </NavLink>
                <span className="text-sm">/</span>
                <span className="pl-2">services</span>
              </div>
              <div className="w-full">
                <div className="store mx10">
                  <p className=" online ">
                    Are you looking for an e-commerce development company in
                    Bangalore? You’ve come to the right place. Setting up an
                    online store for your business is possible with an
                    e-commerce platform.
                  </p>
                </div>
              </div>
              <div className="px-20">
                <p className=" goal pl-8 ml-19">
                  The goal of developing an e-commerce application is to get
                  customers excited and remove unnecessary friction. The company
                  aims to provide high-quality virtual customer service, a
                  seamless online experience, and a fantastic digital product.
                  Whether you are building your business online or optimizing
                  your existing e-commerce site, we will develop a website that
                  converts on time.
                </p>
              </div>
              <div className=" imgs  px-20 ">
                <div className="pt-8 mr-15 hhh">
                  <p>
                    Increasing internet usage has dramatically changed
                    traditional marketing and shopping around the world.
                    E-commerce has expanded to a wider range of products. Over
                    90% of people own smartphones, which makes it easy to buy
                    anything. E-commerce web designand e-commerce website
                    development are the two most important factors for any
                    website to succeed in the internet market today.<br></br>
                    <br></br>
                  </p>
                  <p>
                    The development of an e-commerce website brings in potential
                    customers for whom certain features need to be remembeblack,
                    which saves time, money,and user-centricity.
                  </p>
                  <br></br>
                  <p>
                    Get the best quote from us if you are looking for a Top
                    Ecommerce Website Development Company in Bangalore!
                  </p>
                </div>
                <div className="mt-10 ">
                  <img src="http://localhost:3000/static/media/A_Img.dae818bbfa5ea4f81b6e.webp" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Aservices />
    </div>
  );
};
export default View_More_Services;

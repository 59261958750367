import React from "react";

const PermissionDenied = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>403 - Permission Denied</h1>
      <p>You do not have permission to access this page.</p>
    </div>
  );
};

export default PermissionDenied;

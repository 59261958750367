// AdminLayout.js
import React, { useState, useEffect } from "react";
import { Routes, Route, useResolvedPath } from "react-router-dom";
import Footer from "./Footer.js";
import "../../components/Layout/menubaar.css";
import Header from "./Header.js";
import Dashboard from "../Empolyers/Dasboard.js";
import { Employers } from "./dashboardmenus.js";
import { PopupProvider } from "../Common/PopupContext.js";

function CustomerLayout({ userRole }) {
  // const useLocalStorage = (key) => {
  //   const storedValue = localStorage.getItem(key);
  //   return storedValue;
  // };
  const getCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ");

    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null; // Return null if the cookie is not found
  };
  const useLocalStorage = (key) => {
    // Retrieve from localStorage
    const [storedValue, setStoredValue] = useState(() => {
      const item = localStorage.getItem(key);
      if (item) return item;

      // If not found in localStorage, try to retrieve it from cookies
      const cookieValue = getCookie(key);
      if (cookieValue) {
        // Set to localStorage if found in cookies
        localStorage.setItem(key, cookieValue);
        return cookieValue;
      }

      return null;
    });

    return storedValue;
  };
  const user = useLocalStorage("accessToken");

  useEffect(() => {}, [user]);
  let { path } = useResolvedPath(); // Get the base path for nested routes
  // console.log(user, "user");

  return (
    <PopupProvider>
      <div className="">
        <Header />
        <div className="  hrp-min-h-screen hrp-custom-design-style">
          <div className="w-full   hrp-min-width">
            {user ? <Employers /> : ""}
            <section className="mt-5">
              <div className="bg-white JobCategories-heading-admin mb-20 m-auto">
                <Routes>
                  <Route exact path={path} component={Dashboard} />
                  {/* <Route path={`${path}/profile`} component={Profile} /> */}

                  {/* Add other routes as needed */}
                </Routes>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    </PopupProvider>
  );
}

export default CustomerLayout;

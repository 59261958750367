import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import plan1 from "../../img/wpmantinance/plan1.webp";
import plan2 from "../../img/wpmantinance/plan2.webp";
import plan3 from "../../img/wpmantinance/plan3.webp";
import WpmantinanceLogo from "./../../img/wpmantinance/wp-mantinance-logo.webp";
const Subscription = () => {
  const [showServiceFrequencyDropdown, setShowServiceFrequencyDropdown] =
    useState(false);
  const [expand, setExpand] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const logoRef = useRef(null);
  const [paymentFrequency, setPaymentFrequency] = useState("monthly"); // Default payment frequency
  const [numberOfWebsites, setnumberOfWebsites] = useState("1"); // Default payment frequency
  const [serviceFrequency, setServiceFrequency] = useState("monthly"); // Default payment frequency
  const [showWebsitesDropdown, setShowWebsitesDropdown] = useState(false);
  const [selectedFrequencies, setSelectedFrequencies] = useState({
    basic_care_plan: "Quarterly",
    pro_care_plan: "Monthly",
    premium_care_plan: "Yearly",
  });
  const frequencyLabels = {
    monthly: "Month",
    quarterly: "Quarter",
    yearly: "Year",
  };
  const [plans, setPlans] = useState([
    {
      plan_id: "basic_care_plan",
      plan: "Basic Care Plan",
      price: 20,
      lessprice: 50,
      totalCost: 50,

      padding: "20px",
      image: plan1,
      iconsstrokeColor: "#EE1021",
      discountedPrice: 20,
      numberOfWebsites: 1,
      paymentFrequency: "monthly",
      serviceFrequency: "monthly",
      strokeColor: "#FF5733",
      dropdowncolor: "transparent",
      buttonColor: " linear-gradient(90deg, #EE1021 0%, #A6000D 100%)",
      features: [
        "WordPress Core, Theme, and Plugin Updates",
        "Website Backups",
        "Uptime Monitoring",
        "Basic Security Scans",
        "Email Support (Response Within 48 Hours)",
        "Live Chat Integration",
        "Google Sitemap Updation",
        "Social Media Posting",
        "Google Sheet Creation",
      ],
      offer: "10% off for annual billing!",
    },
    {
      plan_id: "pro_care_plan", // Unique identifier for the plan
      plan: "Pro Care Plan",
      price: 50,
      totalCost: 80,
      lessprice: 80,
      discountedPrice: 50,
      paymentFrequency: "monthly",
      serviceFrequency: "monthly",
      numberOfWebsites: 1,
      mostPopular: true,
      image: plan2,
      mostpopular: "Most Popular",
      strokeColor: "#b05ad0",
      dropdowncolor: "transparent",
      iconsstrokeColor: "#b05ad0",
      buttonColor: "linear-gradient(90deg, #7C229E 0%, #7C229E 100%)",
      features: [
        " WordPress Core, Theme, and Plugin Updates",
        " Website Backups With Restore Options",
        "Uptime Monitoring and Reports",
        "Advanced Security Scans and Firewall Setup",
        "Performance Optimization",
        "24/7 Email and Chat Support (Response Within 24 Hours)",
        "Live Chat Integration",
        "Google Sitemap Updation",
        "Social Media Posting",
        "Google Sheet Creation",
      ],
      offer: "15% off for annual billing!",
    },
    {
      plan_id: "premium_care_plan", // Unique identifier for the plan
      plan: "Premium Care Plan",
      price: 80,
      lessprice: 120,
      discountedPrice: 80,
      totalCost: 120,
      paymentFrequency: "monthly",
      numberOfWebsites: 1,
      serviceFrequency: "monthly",
      image: plan3,
      strokeColor: "#4791FB",
      iconsstrokeColor: "#0160E7",
      dropdowncolor: "transparent",
      buttonColor: "linear-gradient(90deg, #4892FB 0%, #0160E7 100%)",
      features: [
        "WordPress Core, Theme, and Plugin Updates",
        "Website Backups With Restore Options",
        "Uptime Monitoring and Real-Time Reports",
        "Comprehensive Security Scans and Malware Removal",
        "Performance Optimization (Database, Cache, and CDN Integration)",
        "Staging Site for Testing Updates",
        "Monthly Analytics and SEO Reports",
        "Priority Support (Response Within 12 Hours)",
        "Live Chat Integration",
        "Google Sitemap Updation",
        "Social Media Posting",
        "Google Sheet Creation",
      ],
      offer: "20% off for annual billing!",
    },
  ]);
  const [totalCost, setTotalCost] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);

  // Frequency multipliers
  const frequencyMultiplier = {
    monthly: 1,
    quarterly: 3,
    yearly: 12,
  };

  // Discount percentages
  const discountRates = {
    quarterly: 0.05, // 5%
    yearly: 0.1, // 10%
    yearly_quarterly: 0.02, // 2% for yearly payment and quarterly service
  };
  const handleWebsiteChange = (newNumberOfWebsites) => {
    setnumberOfWebsites(newNumberOfWebsites);
    setPlans((prevPlans) =>
      prevPlans.map((plan) => {
        let updatedPlan = { ...plan, numberOfWebsites: newNumberOfWebsites };

        // Recalculate the total cost based on the new number of websites for all plans
        updatedPlan = calculatePricing(updatedPlan);

        return updatedPlan;
      })
    );
  };

  const calculatePricing = (plan) => {
    const paymentMultiplier = frequencyMultiplier[plan.paymentFrequency];
    const serviceMultiplier = frequencyMultiplier[plan.serviceFrequency];
    let totalCost = plan.price * plan.numberOfWebsites * paymentMultiplier;
    let discount = 0;

    if (
      plan.serviceFrequency === "monthly" &&
      plan.paymentFrequency !== "monthly"
    ) {
      discount = totalCost * (discountRates[plan.paymentFrequency] || 0);
    } else if (
      plan.serviceFrequency === "quarterly" &&
      plan.paymentFrequency === "yearly"
    ) {
      discount = totalCost * discountRates.yearly_quarterly;
    }

    return {
      ...plan,
      totalCost,
      discount,
      discountedPrice: totalCost - discount,
    };
  };

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        } else {
          entry.target.classList.remove("visible");
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.5,
    });

    if (logoRef.current) observer.observe(logoRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        console.log("Is logo in viewport?", entry.isIntersecting); // Debugging visibility
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (logoRef.current) {
      observer.observe(logoRef.current);
    }

    return () => {
      if (logoRef.current) {
        observer.unobserve(logoRef.current);
      }
    };
  }, []);

  const navigate = useNavigate();

  // Render the CheckoutPage if a plan is selected
  const handlePlanSelection = (plan) => {
    navigate("/checkout", {
      state: {
        plan,
      },
    });
  };
  const handlePaymentFrequencyChange = (frequency) => {
    setPaymentFrequency(frequency);

    setPlans((prevPlans) =>
      prevPlans.map((plan) => {
        let updatedPlan = { ...plan, paymentFrequency: frequency };

        // Ensure payment frequency is not lower than service frequency
        if (
          frequencyMultiplier[frequency] <
          frequencyMultiplier[plan.serviceFrequency]
        ) {
          updatedPlan.serviceFrequency = frequency; // Match service frequency to payment frequency
        }

        return calculatePricing(updatedPlan);
      })
    );
  };
  const handleServiceFrequencyChange = (frequency) => {
    setServiceFrequency(frequency);

    setPlans((prevPlans) =>
      prevPlans.map((plan) => {
        let updatedPlan = { ...plan, serviceFrequency: frequency };

        // Ensure service frequency is not higher than payment frequency
        if (
          frequencyMultiplier[frequency] >
          frequencyMultiplier[plan.paymentFrequency]
        ) {
          updatedPlan.paymentFrequency = frequency; // Adjust payment frequency to match service frequency
        }

        return calculatePricing(updatedPlan);
      })
    );
  };
  return (
    <section className="">
      <div className="subscription-page">
        <div
          className={`thankyou-WpmantinanceLogo-style ${
            isVisible ? "animate-slide" : ""
          }`}
          ref={logoRef}
        >
          <div
            className={`thankyou-WpmantinanceLogo-style-inner ${
              expand ? "expand" : ""
            }`}
            onClick={() => setExpand(true)}
          >
            {" "}
            <img
              src={WpmantinanceLogo}
              alt="company logo"
              height="100%"
              width="100%"
              className="cursor-pointer"
              onClick={(e) => navigate("/services/wordpress-maintenance")}
            ></img>
          </div>
        </div>

        <div className="container m-auto flex flex-col items-center justify-center py-8 md:py-20 subscription-page-inner">
          <div className="">
            <h1 className="main-wordpess-section-headding  text-white">
              WordPress <span className="checkoutmembership">Maintenance</span>{" "}
              Packages
            </h1>
          </div>
          <div className="lg:flex items-center justify-around w-full p-5">
            <div className="plansdescriptions">
              <p className="m-0 mt-4  wp-matinance-service-covers">
                I need it for
                <div
                  className="custom-dropdown"
                  onMouseEnter={() => setShowWebsitesDropdown(true)}
                  onMouseLeave={() => setShowWebsitesDropdown(false)}
                >
                  <div
                    className="dropdown-toggle text-xl"
                    style={{
                      background: "transparent",
                      border: "unset",
                    }}
                  >
                    <span
                      style={{ borderBottom: "2px dotted #fff" }}
                      className="pl-2.5 pr-2.5"
                    >
                      {numberOfWebsites}
                    </span>
                    {numberOfWebsites > 1 ? "" : ""}
                    <span className="dropdown-arrow"></span>
                    <span> Website</span>
                  </div>
                  {showWebsitesDropdown && (
                    <ul className="dropdown-menu">
                      {[...Array(10)].map((_, i) => (
                        <li
                          key={i + 1}
                          onClick={(e) => handleWebsiteChange(i + 1)}
                        >
                          {i + 1}
                          {i + 1 > 1 ? "" : ""}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </p>
              <div className="wp-matinance-service-frequency">
                <label>Maintenance needed every </label>
                <div
                  className="custom-dropdown"
                  onMouseEnter={() => setShowServiceFrequencyDropdown(true)}
                  onMouseLeave={() => setShowServiceFrequencyDropdown(false)}
                >
                  <div
                    className="dropdown-toggle text-xl"
                    style={{
                      background: "transparent",
                      border: "unset",
                    }}
                  >
                    <span
                      style={{ borderBottom: "2px dotted #fff" }}
                      className="pl-2.5 pr-2.5"
                    >
                      {frequencyLabels[serviceFrequency]}
                    </span>
                    <span className="dropdown-arrow"></span>
                  </div>

                  {showServiceFrequencyDropdown && (
                    <ul className="dropdown-menu">
                      {Object.keys(frequencyLabels).map((frequency) => (
                        <li
                          key={frequency}
                          onClick={() =>
                            handleServiceFrequencyChange(frequency)
                          }
                        >
                          {frequency.charAt(0).toUpperCase() +
                            frequency.slice(1)}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-10 main-payment-freq mb-10 relative">
              <div className=" mostPopularplain absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-1 px-4 h-8">
                Billed
              </div>

              <div className="inner-payment-freq">
                {Object.keys(frequencyMultiplier).map((frequency, index) => (
                  <p
                    key={frequency}
                    className={
                      frequency == paymentFrequency ? "active-payment-freq" : ""
                    }
                    onClick={() => handlePaymentFrequencyChange(frequency)}
                  >
                    {frequency.charAt(0).toUpperCase() + frequency.slice(1)}
                  </p>
                ))}
              </div>
            </div>
          </div>

          <div className="grid lg:grid-cols-3  grid-cols-1 gap-10">
            {plans.map((plan, index) => {
              const selectedFrequency = selectedFrequencies[plan.plan_id];
              return (
                <div
                  key={index}
                  className="text-white rounded-lg shadow-lg basic-plan"
                  style={
                    {
                      // border: `2px solid ${plan.strokeColor}`,
                    }
                  }
                >
                  {plan.mostPopular && (
                    <div className=" mostPopularplain absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-1 px-4 h-8">
                      Most Popular
                    </div>
                  )}
                  <div className="plancard">
                    <div className="plancard-header relative">
                      <div
                        className="flex justify-center"
                        style={{ aspectRatio: "8/3" }}
                      >
                        <img
                          src={plan.image}
                          className="object-fill"
                          alt={`${plan.plan} Image`}
                          width=""
                          height=""
                          loading="lazy"
                        />
                      </div>
                      <div className="card-heading">
                        <div>
                          {" "}
                          <h2 className="  text-center xl:my-1 lg:mb-0 lg:mt-0">
                            {plan.plan}
                          </h2>
                          <div className="discount-outer-section pendulum-animation">
                            <div className="discount-section">
                              <div className="discount-section-inner">
                                <p className="discount-amount">
                                  <span className="discount-value">
                                    {/* ${totalCost.toFixed(2)} */}$
                                    {plan?.discountedPrice.toFixed(2)}
                                  </span>
                                </p>
                                <p className="original-price">
                                  <strong>${plan?.totalCost.toFixed(2)}</strong>
                                </p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                    <ul className="  py-2 px-4">
                      {plan.features.map((feature, i) => (
                        <li
                          key={i}
                          className="flex md:items-center description-inner"
                        >
                          <div>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 11L12 14L20 6"
                                stroke={plan.strokeColor}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20 12V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H6C5.46957 20 4.96086 19.7893 4.58579 19.4142C4.21071 19.0391 4 18.5304 4 18V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H15"
                                stroke={plan.strokeColor}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <p>
                            {feature
                              .replace(
                                "WordPress Core, Theme, and Plugin Updates",
                                `${selectedFrequency} WordPress Core, Theme, and Plugin Updates`
                              )
                              .replace(
                                "Website Backups",
                                `${selectedFrequency} Website Backups`
                              )}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {plan.id === "pro_care_plan" && (
                    <div>
                      {plan.features.map((feature, i) => (
                        <div key={i} className="mostpopular-section">
                          {plan.mostpopular}
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="subscription-btn">
                    <div
                      className="flex justify-center items-center subscription-btn-inner"
                      style={{
                        background: plan.buttonColor,
                      }}
                      onClick={() => handlePlanSelection(plan)}
                    >
                      <button className="flex items-center justify-center ">
                        Choose Plan
                      </button>
                      <span className=" flex items-center justify-center w-12 h-12 bg-white rounded-full ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          height="20"
                          width="20"
                          style={{
                            fill: plan.iconsstrokeColor,
                          }}
                        >
                          <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="sphere_section">
          <div className="spherebubble-sphere_3 spherebubble"></div>
          <div className="spherebubble-sphere_6 spherebubble"></div>
          <div className="spherebubble-sphere_7 spherebubble"></div>
          <div className="spherebubble-sphere_8 spherebubble"></div>
        </div>
      </div>
    </section>
  );
};

export default Subscription;

import React from "react";
import "../../App.css";
const Popup = ({ show, onClose, title, children }) => {
  if (!show) return null;

  return (
    <div className="modal-details-fade ContactVoice">
      <div className="modal-details">
        {/* Close Button */}

        <i
          className="fa-regular fa-circle-xmark text-red-600"
          style={{ float: "right", fontSize: "22px", cursor: "pointer" }}
          onClick={onClose}
        />

        {/* Popup Title */}
        {title && <h2 className="text-lg font-semibold mb-4">{title}</h2>}

        {/* Popup Content */}
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Popup;

import React, { useState } from "react";
import Helmet from "react-helmet";
import logo from "../../../img/logo192.png";
const HexToRgbConverter = () => {
  const [hex, setHex] = useState("");
  const [rgb, setRgb] = useState("");

  const hexToRgb = (hex) => {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, "");

    // Parse r, g, b values
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    return `rgb(${r}, ${g}, ${b})`;
  };

  const handleChange = (e) => {
    const hexValue = e.target.value;
    setHex(hexValue);

    // Validate hex value
    if (/^#?[0-9A-Fa-f]{6}$/.test(hexValue)) {
      setRgb(hexToRgb(hexValue));
    } else {
      setRgb("Invalid HEX code");
    }
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="" />
        <meta
          name="title"
          content="Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" coding validation tools, programming tools, code checker, code error finding tool, code beautify tool, code scanning tool"
        />
        <meta
          name="description"
          content=" Discover top coding validation tools to ensure error-free, high-quality code. Learn how tools like HTML Preview, Minyfiy to UnMinyfiy, Json Validator, Validate CSS, String Replace, Webhook Test can streamline your development workflow and enforce coding standards. "
        />
        {/* <link rel="apple-touch-icon" href={logo} /> */}
        <title>
          Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech
        </title>
      </Helmet>
      <div className="flex flex-col items-center p-6 bg-gray-100 min-h-screen">
        <h1 className="text-3xl font-bold mb-4">Hex to RGB Converter</h1>
        <input
          type="text"
          value={hex}
          onChange={handleChange}
          placeholder="#000000"
          className="border rounded p-2 w-64 mb-4 text-center"
        />
        <p className="text-xl font-semibold">RGB Value: {rgb}</p>
      </div>
    </>
  );
};

export default HexToRgbConverter;

import React, { useState } from "react";
import axios from "axios";
import Helmet from "react-helmet";
import logo from "../../../img/logo192.png";
const WebhookTester = () => {
  const [url, setUrl] = useState("");
  const [method, setMethod] = useState("GET");
  const [headers, setHeaders] = useState([{ key: "", value: "" }]);
  const [body, setBody] = useState("");
  const [bodyError, setBodyError] = useState("");
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const handleHeaderChange = (index, event) => {
    const newHeaders = headers.map((header, i) => {
      if (i !== index) return header;
      return { ...header, [event.target.name]: event.target.value };
    });
    setHeaders(newHeaders);
  };

  const addHeader = () => {
    setHeaders([...headers, { key: "", value: "" }]);
  };

  const removeHeader = (index) => {
    setHeaders(headers.filter((_, i) => i !== index));
  };

  const handleBodyChange = (event) => {
    setBody(event.target.value);
    try {
      JSON.parse(event.target.value);
      setBodyError("");
    } catch (err) {
      setBodyError("Invalid JSON");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (bodyError) {
      setError("Please fix JSON errors before submitting");
      return;
    }

    let headersObj = {};
    headers.forEach((header) => {
      if (header.key) {
        headersObj[header.key] = header.value;
      }
    });

    let bodyObj = {};
    try {
      bodyObj = body ? JSON.parse(body) : {};
    } catch (err) {
      setError("Invalid JSON in body");
      return;
    }

    try {
      const res = await axios({
        method,
        url,
        headers: headersObj,
        data: bodyObj,
      });
      setResponse(res.data);
      setError(null);
    } catch (err) {
      setError(err.message);
      setResponse(null);
    }
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="" />
        <meta
          name="title"
          content="Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" coding validation tools, programming tools, code checker, code error finding tool, code beautify tool, code scanning tool"
        />
        <meta
          name="description"
          content=" Discover top coding validation tools to ensure error-free, high-quality code. Learn how tools like HTML Preview, Minyfiy to UnMinyfiy, Json Validator, Validate CSS, String Replace, Webhook Test can streamline your development workflow and enforce coding standards. "
        />
        {/* <link rel="apple-touch-icon" href={logo} /> */}
        <title>
          Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech
        </title>
      </Helmet>
      <div className="bg-gray-100 p-10">
        <div className="max-w-screen-lg mt-8 border-2 m-auto p-10">
          <h2 className="text-2xl font-semibold mb-4">Webhook Tester</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block mb-1">URL: </label>
              <input
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                required
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block mb-1">Method: </label>
              <select
                value={method}
                onChange={(e) => setMethod(e.target.value)}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              >
                <option value="GET">GET</option>
                <option value="POST">POST</option>
                <option value="PUT">PUT</option>
                <option value="DELETE">DELETE</option>
              </select>
            </div>
            <div>
              <label className="block mb-1">Headers: </label>
              {headers.map((header, index) => (
                <div key={index} className="flex items-center space-x-2 pt-4">
                  <input
                    type="text"
                    name="key"
                    value={header.key}
                    onChange={(e) => handleHeaderChange(index, e)}
                    placeholder="Key"
                    className="w-1/3 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  />
                  <input
                    type="text"
                    name="value"
                    value={header.value}
                    onChange={(e) => handleHeaderChange(index, e)}
                    placeholder="Value"
                    className="w-1/3 px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  />
                  <button
                    type="button"
                    onClick={() => removeHeader(index)}
                    className="px-3 py-2 bg-red-500 text-white rounded-md"
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={addHeader}
                className="mt-2 px-3 py-2 bg-blue-500 text-white rounded-md"
              >
                Add Header
              </button>
            </div>
            <div>
              <label className="block mb-1">Body (JSON): </label>
              <textarea
                value={body}
                onChange={handleBodyChange}
                placeholder='{"key": "value"}'
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                rows={6}
              />
              {bodyError && <div className="text-red-500">{bodyError}</div>}
            </div>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Submit
            </button>
          </form>
          {response && (
            <div className="mt-4">
              <h3 className="text-xl font-semibold">Response:</h3>
              <pre>{JSON.stringify(response, null, 2)}</pre>
            </div>
          )}
          {error && (
            <div className="mt-4">
              <h3 className="text-xl font-semibold">Error:</h3>
              <pre>{error}</pre>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WebhookTester;

// AdminLayout.js
import React, { useState, useEffect } from "react";
import { Routes, Route, useResolvedPath } from "react-router-dom";
import Footer from "./Footer";
import "../../components/Layout/menubaar.css";
import Header from "./Header";
import Dashboard from "../SuperAdmin/Dasboard";
import { SuperAdmin } from "./dashboardmenus";
import { PopupProvider } from "../Common/PopupContext";
function SuperAdminLayout() {
  const getCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ");

    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null; // Return null if the cookie is not found
  };
  const useLocalStorage = (key) => {
    // Retrieve from localStorage
    const [storedValue, setStoredValue] = useState(() => {
      const item = localStorage.getItem(key);
      if (item) return item;

      // If not found in localStorage, try to retrieve it from cookies
      const cookieValue = getCookie(key);
      if (cookieValue) {
        // Set to localStorage if found in cookies
        localStorage.setItem(key, cookieValue);
        return cookieValue;
      }

      return null;
    });

    return storedValue;
  };
  const user = useLocalStorage("accessToken");

  useEffect(() => {}, [user]);
  let { path } = useResolvedPath(); // Get the base path for nested routes

  return (
    <PopupProvider>
      {/* <Header /> */}
      {user ? <SuperAdmin /> : ""}
      <div className=" hrp-min-h-screen hrp-custom-design-style  ajivaloginpage">
        <section className="ajv-maintenance-page-inner h-screen">
          <div className=" JobCategories-heading-admin mb-20  container m-auto">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              {/* <Route exact path="/" element={"kdddddddddddddddddddddddddd"} /> */}

              {/* Add other routes as needed */}
            </Routes>
          </div>
        </section>
      </div>
    </PopupProvider>
  );
}

export default SuperAdminLayout;

import React, { useState } from "react";
import Helmet from "react-helmet";

import logo from "../../../img/logo192.png";
const Base64Encoder = () => {
  const [input, setInput] = useState("");
  const [encoded, setEncoded] = useState("");

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInput(value);
    setEncoded(btoa(value));
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(encoded)
      .then(() => {
        alert("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="" />
        <meta
          name="title"
          content="Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" coding validation tools, programming tools, code checker, code error finding tool, code beautify tool, code scanning tool"
        />
        <meta
          name="description"
          content=" Discover top coding validation tools to ensure error-free, high-quality code. Learn how tools like HTML Preview, Minyfiy to UnMinyfiy, Json Validator, Validate CSS, String Replace, Webhook Test can streamline your development workflow and enforce coding standards. "
        />
        {/* <link rel="apple-touch-icon" href={logo} /> */}
        <title>
          Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech
        </title>
      </Helmet>
      <div className="flex flex-col items-center p-4 max-w-lg mx-auto bg-white shadow-md rounded-lg">
        <h2 className="text-2xl font-bold mb-4">Base64 Encoder</h2>
        <textarea
          className="w-full p-2 mb-4 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={input}
          onChange={handleInputChange}
          placeholder="Enter text to encode"
          rows="5"
        />
        <h3 className="text-xl font-semibold mb-2">Encoded Result:</h3>
        <textarea
          className="w-full p-2 mb-4 border border-gray-300 rounded bg-gray-100 focus:outline-none"
          value={encoded}
          readOnly
          rows="5"
        />
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
          onClick={copyToClipboard}
        >
          Copy to Clipboard
        </button>
      </div>
    </>
  );
};

export default Base64Encoder;

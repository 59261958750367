// AdminLayout.js
import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom"; // Updated imports
import Footer from "./Footer";
import "../../components/Layout/menubaar.css";
import "../../../src/css/responcive.css";
import { CVDownloadProvider } from "../../helpers/CVDownloadContext";
import Header from "./Header";
import Career from "../../components/Website/Career.js";
import About from "../../components/Website/About";
import Contact from "../../components/Website/Contact";
import Portfolio from "../../components/Website/Portfolio";
import Services from "../../components/Website/Services";
import EcommerceService from "../../components/Website/EcommerceService";
import ApiIntegration from "../../components/Website/ApiIntegration";
import View_more_services from "../../components/Website/View_more_services";
import Web_Development from "../../components/Website/Web_Development";
import Website_Design from "../../components/Website/Website_Design";
import SoftwareDevelopment from "../../components/Website/SoftwareDevelopment";
import Crm_development from "../../components/Website/Crm.js";
import Mobile_App_Development from "../../components/Website/Mobile_App_Development";
import Aservices from "../../components/Website/Aservices";
import Graphi_Design from "../../components/Website/Web & Graphic Design";
import Signup from "../../components/Website/Signup.js";
import Registepage from "../../components/Website/Registerform.js";
import Loginform from "../../components/Website/Signup.js";
import ForgotPassword from "../../components/Website/ForgotPassword.js";
import Home from "../../components/Website/home.js";
import ResetPassword from "../../components/Website/ResetPassword.js";
import Case_studies from "../../components/Website/CaseStudies";
import Shipping_Policy from "../../components/Website/Shipping_Policy";
import Terms_Conditions from "../../components/Website/Terms&Conditions";
import Refund_Policy from "../../components/Website/Refund_Policy";
import Privacy_Policy from "../../components/Website/Privacy_Policy";
import Blog_Title from "../../components/Website/Blog_tittale";
import Blog from "../../components/Website/Blog";
import Frontend from "../../components/Website/frontend";
import NotFound from "../../components/Website/NotFound";
import Toolscomponant from "../../components/Website/tools/Toolssitemap";
import Stringify from "../../components/Website/tools/stringfy";
import Jsionchanger from "../../components/Website/tools/jsionchanger";
import Minyfiy from "../../components/Website/tools/minyfiy";
import Codedubager from "../../components/Website/tools/codedebuger";
import All_tools_component from "../../components/Website/allTools";
import PHP_runner from "../../components/Website/tools/PHP_runner";
import SMPT from "../../components/Website/tools/SMPT";
import HTML_and_Preview from "../../components/Website/tools/HTML_and_Preview";
import Webhook_Test from "../../components/Website/tools/Webhook_Test";
import Convert_Hex_Color_to_RGB from "../../components/Website/tools/Convert_Hex_Color_to_RGB";
import Convert_RGB_Color_to_Hex from "../../components/Website/tools/RGB_to_HEX";
import Convert_Xml_to_json from "../../components/Website/tools/Xml_to_json";
import Convert_json_to_Xml from "../../components/Website/tools/Json_to_xml";
import Base64Encode from "../../components/Website/tools/Base64Encode";
import Base64Dncode from "../../components/Website/tools/Base64Decode";
import URLEncode from "../../components/Website/tools/URLEncode";
import URLDecode from "../../components/Website/tools/URLDecode";
import Convert_PNG_to_JPG from "../../components/Website/tools/Convert_PNG_to_JPG";
import Convert_UNIX_Time_to_UTC from "../../components/Website/tools/Convert_UNIX_Time_to_UTC";
import Convert_H_M_S_to_Seconds from "../../components/Website/tools/Convert_H_to_Seconds";
import Random_Time_Generator from "../../components/Website/tools/Random_Time_Generator";
import Create_Bcrypt_Password from "../../components/Website/tools/Create_Bcrypt_Password";
import Convert_JPG_to_PNG from "../../components/Website/tools/Convert_JPG_to_PNG";
import Convert_GIF_to_PNG from "../../components/Website/tools/Convert_GIF_to_PNG";
import WordPressMaintenanceDev from "../../components/Website/WP_Maintenanceservicedev.js";
import WordPressMaintenance from "../../components/Website/WP_Maintenanceservice";
import Subscription from "../../components/Website/Subscription";
import CheckoutPage from "../../components/Website/CheckoutPage.js";
import ThankYou from "../../components/Website/ThankYouWordPress.js";
import OnBoardPage from "../../components/Website/OnBoardSteps.js";
import CssValidator from "../../components/Website/tools/Validate_CSS";
import { PopupProvider } from "../Common/PopupContext";
import { getUserData } from "../../helpers/utils";
function Websitelayout() {
  const useLocalStorage = (key) => {
    const storedValue = localStorage.getItem(key);
    return storedValue;
  };
  const location = useLocation();
  const withoutHeaderAndFooterLayout = [
    "/services/wordpress-maintenance",
    "/services/wordpress-maintenancedev",
    "/subscription",
    "/thankyou",
    "/checkout",
    "/onboarding",
    "/login",
  ];
  const showHeaderAndFooter = !withoutHeaderAndFooterLayout.includes(
    window.location.pathname
  );
  const user = useLocalStorage("accessToken");
  useEffect(() => {}, [user]);
  const User = getUserData(); // Get user data from localStorage
  const role = User ? User.role : null; // Get the base path for nested routes
  const renderComponentBasedOnRole = (role) => {
    switch (role) {
      case "superadmin":
        return <Header />;
      case "applicant":
        return <Header />;
      case "employer":
        return <Header />;
      default:
        return <Header />; // Default to general header for non-logged-in users or unknown roles
    }
  };
  useEffect(() => {
    // This will trigger on location change if needed
    console.log("Location changed:", location.pathname);
  }, [location]);
  return (
    <CVDownloadProvider>
      <PopupProvider>
        {showHeaderAndFooter && <Header />}
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/signup" element={<Signup />} /> */}
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/login" element={<Loginform />} />
          <Route path="/register" element={<Registepage />} />
          <Route path="/career" element={<Career />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/services" element={<Services />} />
          <Route path="/aservices" element={<Aservices />} />
          <Route path="/case_studies" element={<Case_studies />} />
          <Route path="/shipping&policy" element={<Shipping_Policy />} />
          <Route path="/refund&policy" element={<Refund_Policy />} />
          <Route path="/privacy&policy" element={<Privacy_Policy />} />
          <Route path="/terms&conditions" element={<Terms_Conditions />} />
          <Route path="/tools/codedubager" element={<Codedubager />} />
          <Route path="/tools/Minyfiy" element={<Minyfiy />} />
          <Route path="/tools/" element={<All_tools_component />} />
          <Route path="/tools/sitemaps" element={<Toolscomponant />} />
          <Route path="/tools/strreplace" element={<Stringify />} />
          <Route path="/tools/jsonvalidator" element={<Jsionchanger />} />
          <Route path="/tools/php" element={<PHP_runner />} />
          <Route path="/tools/html" element={<HTML_and_Preview />} />
          <Route path="/tools/webhook" element={<Webhook_Test />} />
          <Route path="/tools/smpt" element={<SMPT />} />
          <Route path="/tools/base64-encode" element={<Base64Encode />} />
          <Route path="/tools/base64-decode" element={<Base64Dncode />} />
          <Route path="/tools/url-encode" element={<URLEncode />} />
          <Route path="/tools/url-decode" element={<URLDecode />} />
          <Route path="/tools/png-to-jpg" element={<Convert_PNG_to_JPG />} />
          <Route path="/tools/xml-to-json" element={<Convert_Xml_to_json />} />
          <Route path="/tools/xml-minify" element={<Convert_json_to_Xml />} />
          <Route
            path="/tools/rgb-to-hex"
            element={<Convert_RGB_Color_to_Hex />}
          />
          <Route
            path="/tools/hex-to-rgb"
            element={<Convert_Hex_Color_to_RGB />}
          />
          <Route
            path="/tools/utc-to-unix"
            element={<Convert_UNIX_Time_to_UTC />}
          />
          <Route
            path="/tools/hms-to-seconds"
            element={<Convert_H_M_S_to_Seconds />}
          />
          <Route
            path="/tools/random-time"
            element={<Random_Time_Generator />}
          />
          <Route path="/tools/bcrypt" element={<Create_Bcrypt_Password />} />
          <Route path="/tools/jpg-to-png" element={<Convert_JPG_to_PNG />} />
          <Route path="/tools/gif-to-png" element={<Convert_GIF_to_PNG />} />
          <Route path="/tools/css-validate" element={<CssValidator />} />
          <Route
            path="services/view_more_services"
            element={<View_more_services />}
          />
          <Route
            path="/services/mobile-app-development"
            element={<Mobile_App_Development />}
          />
          <Route
            path="/services/crm-development"
            element={<Crm_development />}
          />
          <Route path="/services/frontend-development" element={<Frontend />} />
          <Route path="/blog/:id" element={<Blog_Title />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route
            path="/services/web-development"
            element={<Web_Development />}
          />
          <Route
            path="/services/ecommerce-development"
            element={<EcommerceService />}
          />
          <Route
            path="/services/api-integration"
            element={<ApiIntegration />}
          />
          <Route path="/services/graphic-design" element={<Graphi_Design />} />
          <Route path="/services/website-design" element={<Website_Design />} />
          <Route
            path="/services/software-development"
            element={<SoftwareDevelopment />}
          />
          <Route
            path="/services/wordpress-maintenance"
            element={<WordPressMaintenance />}
          />
          <Route
            path="/services/wordpress-maintenancedev"
            element={<WordPressMaintenanceDev />}
          />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/onboarding" element={<OnBoardPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>

        {showHeaderAndFooter && <Footer />}
      </PopupProvider>
    </CVDownloadProvider>
  );
}

export default Websitelayout;

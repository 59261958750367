import React, { useState } from "react";
import prettier from "prettier/standalone";
import parserHtml from "prettier/parser-html";
import Helmet from "react-helmet";

import logo from "../../../img/logo192.png";
const HtmlEditor = () => {
  const [htmlCode, setHtmlCode] = useState("");
  const [beautifiedCode, setBeautifiedCode] = useState("");

  const handleBeautify = () => {
    try {
      const formattedCode = prettier.format(htmlCode, {
        parser: "html",
        plugins: [parserHtml],
      });
      setBeautifiedCode(formattedCode);
    } catch (error) {
      console.error("Error beautifying the code:", error);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert("Code copied to clipboard!");
    });
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="" />
        <meta
          name="title"
          content="Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" coding validation tools, programming tools, code checker, code error finding tool, code beautify tool, code scanning tool"
        />
        <meta
          name="description"
          content=" Discover top coding validation tools to ensure error-free, high-quality code. Learn how tools like HTML Preview, Minyfiy to UnMinyfiy, Json Validator, Validate CSS, String Replace, Webhook Test can streamline your development workflow and enforce coding standards. "
        />
        {/* <link rel="apple-touch-icon" href={logo} /> */}
        <title>
          Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech
        </title>
      </Helmet>

      <div className="p-10">
        <div className="p-10">
          <h1 class="text-3xl text-center font-bold text-gray-600 bg-white  p-6 rounded-lg">
            Html and Preview
          </h1>
          <div className="flex gap-5">
            <div className="w-1/2">
              <textarea
                rows="10"
                cols="80"
                value={htmlCode}
                onChange={(e) => setHtmlCode(e.target.value)}
                placeholder="Enter your HTML code here"
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              ></textarea>
            </div>
            <div className=" w-1/2">
              <pre className="p-3  bg-gray-100 rounded-md border-2  min-h-[250px] ">
                {beautifiedCode}
              </pre>
            </div>
          </div>

          <div className="mt-3 space-x-3">
            <button
              onClick={handleBeautify}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Beautify Code
            </button>
            <button
              onClick={() => copyToClipboard(beautifiedCode)}
              className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
            >
              Copy Beautified Code
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HtmlEditor;

import React from "react";
import Group from "../../img/wpmantinance/Group 9.webp";
import { useNavigate } from "react-router-dom";
import WpmantinanceLogo from "./../../img/wpmantinance/wp-mantinance-logo.webp"; // For navigation
const OnboardingSection = () => {
  const navigate = useNavigate(); // Hook for navigation

  const handleRedirect = () => {
    navigate("/onboarding"); // Redirects to the onboarding page
  };
  return (
    <div className="main-wordpess-section-thankyou min-h-screen from-black via-gray-900 to-black text-white px-6 py-12 flex flex-col items-center">
      <div className="thankyou-WpmantinanceLogo-style">
        <div className="thankyou-WpmantinanceLogo-style-inner ">
          <img
            src={WpmantinanceLogo}
            alt="company logo"
            height="100%"
            className="cursor-pointer"
            width="100%"
            onClick={(e) => navigate("/services/wordpress-maintenance")}
          ></img>
        </div>
      </div>
      <div className="container thankyoupage">
        {/* Header Section */}
        <div className="text-center mb-12 mt-20">
          <h1 className="text-4xl font-bold mb-4 text-white">
            Welcome to Your Website{" "}
            <span className="checkoutmembership">Maintenance Journey</span>{" "}
          </h1>
          <div className="image flex ">
            <img src={Group} className="photo m-auto"></img>
          </div>
          <h1 className="text-2xl font-extrabold">Welcome</h1>
          <p className="mt-4 text-lg text-gray-300 w-3/5 m-auto">
            Thank you for subscribing! We’re excited to maintain your website.
            Let’s start by collecting the necessary access details to ensure a
            smooth onboarding process.
          </p>
        </div>
        {/* Call to Action */}
        <h1 className="text-2xl font-bold mb-4 text-center">
          Help us serve you better by sharing your website details
        </h1>
        <div className="mt-12 text-center">
          <button
            onClick={handleRedirect}
            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-3 px-8 rounded-lg transition duration-200"
          >
            Let’s Begin
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingSection;

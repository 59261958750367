import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import blog_img from "../../img/services_img.webp";
import Services_bg from "../../img/background_img/Services.webp";
import logo from "../../img/logo192.png";
const Services = () => {
  useEffect(() => {
    setkk(true);
  }, []);
  const [kk, setkk] = useState(false);
  const [kk1, setkk1] = useState(false);
  const [kk2, setkk2] = useState(false);
  const [kk3, setkk3] = useState(false);
  const [kk4, setkk4] = useState(false);
  const [kk5, setkk5] = useState(false);
  const [kk6, setkk6] = useState(false);
  useEffect(() => {
    setkk(true);
  }, []);
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="title"
          content="Web & Graphic Design, Development, and E-commerce, and CMS Solutions by Expert Full-Stack Developers | Ajiva Infotech          "
        />
        <title>
          Web & Graphic Design, Development, and E-commerce, and CMS Solutions
          by Expert Full-Stack Developers | Ajiva Infotech
        </title>

        <meta name="robots" content="index, follow"></meta>
        <meta content="Discover AjivaInfotech, a leading technology partner offering end-to-end software development and testing services. Transform  digital presence today."></meta>
        <meta
          name="description"
          content="Unlock your digital potential with top-notch services in Web & Graphic Design, Web Development, Mobile App Development, E-COMMERCE, CMS Development, and expert Front-end & Backend Development. Elevate your online presence today!
          "
        />
        <meta
          name="keywords"
          content="Web & Graphic Design, Web Development, Mobile App Development, E-COMMERCE, CMS Development, Front-end Development & Backend Development, Web Solutions Agency,  PHP Developer, Nord.js, React, zapier, Full Stack Developers, Wordpress AMC services, CRM development, App development, Software solutions
"
        />
        <link rel="apple-touch-icon" href={logo} />
      </Helmet>
      <section className="container-fluid m-auto">
        <div className="w-full">
          <img className="w-full" src={Services_bg} alt="Services_bg" />
        </div>
      </section>
      <section className="aj_portfolio_2">
        <div className="container  m-auto">
          <div className="w-full">
            <div className="text-center my-8">
              <h2 className="text-5xl text-gray-800 font-semibold">
                Our Services
              </h2>
              {/* <p className="text-xl font-medium py-3">
                2000+ awesome projects delivered in 50+ nations earned us the
                respect we proudly cherish today.
              </p> */}
            </div>
          </div>
          <div class="border-b-2 border-[#878787] py-0 my-5 mx-4 border-dotted py-4"></div>
        </div>
      </section>

      <section>
        <div className="container m-auto">
          <div className="w-full lg:flex">
            <div className="lg:w-full lg:flex">
              <div className="bg-gray-800 my-4 lg:w-2/6 h-max overflow-auto">
                {/* ------------------------------------------old------------------------------------------------------------------ */}
                <ul className="py-4">
                  <li
                    className={
                      kk
                        ? "active text-red-600 px-4 font-semibold border-r-2 border-red-600 py-4   text-lg bg-white"
                        : "text-white mx-4 py-4 border-b border-dotted text-lg cursor-pointer"
                    }
                    onClick={(e) => {
                      setkk(true);
                      setkk1(false);
                      setkk2(false);
                      setkk3(false);
                      setkk4(false);
                      setkk5(false);
                      setkk6(false);
                    }}
                  >
                    <i class="fa-solid fa-angle-right mx-2 "></i>
                    <span>Designing</span>
                  </li>
                  <li
                    className={
                      kk1
                        ? "active text-red-600 px-4 font-semibold border-r-2 border-red-600 py-4   text-lg bg-white"
                        : "text-white mx-4 py-4 border-b border-dotted text-lg cursor-pointer"
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(true);
                      setkk2(false);
                      setkk3(false);
                      setkk4(false);
                      setkk5(false);
                      setkk6(false);
                    }}
                  >
                    <i class="fa-solid fa-angle-right mx-2 "></i>
                    <span>Web Development</span>
                  </li>
                  <li
                    className={
                      kk2
                        ? "active text-red-600 px-4 font-semibold border-r-2 border-red-600 py-4   text-lg bg-white"
                        : "text-white mx-4 py-4 border-b border-dotted text-lg cursor-pointer"
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(false);
                      setkk2(true);
                      setkk3(false);
                      setkk4(false);
                      setkk5(false);
                      setkk6(false);
                    }}
                  >
                    <i class="fa-solid fa-angle-right mx-2 "></i>
                    <span>Mobile App Development</span>
                  </li>
                  <li
                    className={
                      kk3
                        ? "active text-red-600 px-4 font-semibold border-r-2 border-red-600 py-4   text-lg bg-white"
                        : "text-white mx-4 py-4 border-b border-dotted text-lg cursor-pointer"
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(false);
                      setkk2(false);
                      setkk3(true);
                      setkk4(false);
                      setkk5(false);
                      setkk6(false);
                    }}
                  >
                    <i class="fa-solid fa-angle-right mx-2 "></i>
                    <span>Ecommerce Development</span>
                  </li>
                  <li
                    className={
                      kk4
                        ? "active text-red-600 px-4 font-semibold border-r-2 border-red-600 py-4   text-lg bg-white"
                        : "text-white mx-4 py-4 border-b border-dotted text-lg cursor-pointer"
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(false);
                      setkk2(false);
                      setkk3(false);
                      setkk4(true);
                      setkk5(false);
                      setkk6(false);
                    }}
                  >
                    <i class="fa-solid fa-angle-right mx-2"></i>
                    <span>CMS Development </span>
                  </li>
                  <li
                    className={
                      kk5
                        ? "active text-red-600 px-4 font-semibold border-r-2 border-red-600 py-4   text-lg bg-white"
                        : "text-white mx-4 py-4 border-b border-dotted text-lg cursor-pointer"
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(false);
                      setkk2(false);
                      setkk3(false);
                      setkk4(false);
                      setkk5(true);
                      setkk6(false);
                    }}
                  >
                    <i class="fa-solid fa-angle-right mx-2"></i>
                    <span>Front-end Development </span>
                  </li>
                  <li
                    className={
                      kk6
                        ? "active text-red-600 px-4 font-semibold border-r-2 border-red-600 py-4   text-lg bg-white"
                        : "text-white mx-4 py-4 border-b border-dotted text-lg cursor-pointer"
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(false);
                      setkk2(false);
                      setkk3(false);
                      setkk4(false);
                      setkk5(false);
                      setkk6(true);
                    }}
                  >
                    <i class="fa-solid fa-angle-right mx-2"></i>
                    <span>Backend Development</span>
                  </li>
                  <option></option>
                </ul>
                {/* --------------------------------------------old end----------------------------------------------- */}
              </div>
              <div className="lg:w-4/6	">
                <div className="">
                  {/* ==========================this is Web & Graphic Design================================================= */}
                  {kk == true ? (
                    <div className="border-2 border-[#cccccc] mx-4 my-4">
                      <div className="mx-2 w-full lg:flex ">
                        <div className="lg:w-2/6">
                          <img
                            src={blog_img}
                            alt="blog_img"
                            className="m-0 w-full lg:mx-2"
                          />
                        </div>
                        <div className="lg:py-6 mx-6 lg:w-4/6">
                          <h2 className="text-2xl font-bold text-[#343434]">
                            <a href="graphic-design"> Web & Graphic Design</a>
                          </h2>
                          {/* <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                        Web Development
                      </h3> */}
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Web & Graphic design in an impressive way is not
                            just a profession but a passion for our Team. We
                            believe in the idea that a good look of any website
                            is the first impression on visitors. Good design and
                            graphics of any website is the starting point of any
                            website. We have a special team for Website Design
                            that has been involved in designing professional
                            websites of all categories. Our websites and search
                            engines are optimized and user-friendly.
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Connecting creative, technology, and commerce to
                            drive brands and business growth. We have been
                            recognized and awarded for our exceptional &
                            excellent work. We work for results & awards provide
                            us motivation.
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Ajiva Infotech Pvt Ltd provides full customization
                            while designing your website. More importantly,
                            Ajiva Infotech Pvt Ltd use a well-planned approach
                            in order to design SEO SEO-friendly website so that
                            you gain full benefit out of these. This website
                            will cost you very less.
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Because of our extensive experience in this area, we
                            know exactly what makes a website work, and will
                            ensure that your website meets your expectations.
                            Through a simple process, we will work with you
                            through the development stage, ensuring you get
                            exactly what you want out of your site while making
                            sure we provide you with the right advice so that
                            you end up with a product that you, and the customer
                            using it, will be happy with.
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Get Your Business Online Quickly and Easily With
                            Ajiva Infotech Pvt Ltd Professional Web design and
                            Graphic design Company
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            If you're looking for the perfect website design to
                            set your business apart from the competition, look
                            no further than Ajiva Infotech Pvt Ltd. Our
                            professional Web Designing Company offer customized
                            web designs tailored to fit your unique needs and
                            goals. From ecommerce websites to custom-made
                            designs, our development team can create an online
                            presence that will help you get noticed and convert
                            more customers.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            Benefits of Working with Ajiva Infotech Pvt Ltd
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Working with a professional web design company like
                            Ajiva Infotech Pvt Ltd has its perks. Here are a
                            some advantages of working with us:
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            1. We have a team of experienced designers who will
                            work tirelessly to create a stunning website for
                            your business.
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            2. We use the latest technologies and trends to
                            create websites that are not only visually appealing
                            but also highly functional.
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            3. We offer affordable web design services without
                            compromising on quality or features.
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            4. We provide comprehensive support and maintenance
                            services to keep your website up and running
                            smoothly at all times.
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            5. We also offer additional digital marketing
                            services to help you get more leads and customers
                            from your website.
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            With a keen eye for detail and a passion for
                            creativity, we aim to exceed expectations and
                            deliver designs that resonate with the target
                            audience and elevate our clients' brands to new
                            heights.
                          </p>

                          <div className="">
                            <h1 className="text-red-600 my-2 font-bold">
                              Type of Services
                            </h1>
                            <div className="lg:grid grid-cols-2 gap-3">
                              <h5 className="text-base font-semibold text-gray-600">
                                <i class="fa-solid fa-angle-right mx-2 text-red-600"></i>
                                Web Design
                              </h5>
                              <h5 className="text-base font-semibold text-gray-600">
                                <i class="fa-solid fa-angle-right mx-2 text-red-600"></i>
                                Graphic Design
                              </h5>
                              <h5 className="text-base font-semibold text-gray-600">
                                <i class="fa-solid fa-angle-right mx-2 text-red-600"></i>
                                Mobile Apps Design
                              </h5>
                              <h5 className="text-base font-semibold text-gray-600">
                                <i class="fa-solid fa-angle-right mx-2 text-red-600"></i>
                                Branding Services
                              </h5>
                            </div>
                          </div>
                          <div className="flex mt-2 items-center	">
                            <a
                              href="/services/graphic-design"
                              className="text-red-600 font-semibold   "
                            >
                              Read more
                            </a>
                            <div>
                              <a
                                href="/services/graphic-design"
                                className="text-black-600 text-1xl font-semibold "
                              />

                              <i class=" fa-solid fa-arrow-right-long  text-red-700 atag" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="">
                  {kk1 == true ? (
                    <div className="border-2 border-[#cccccc] mx-4 my-4">
                      <div className="mx-2 w-full lg:flex">
                        <div className="lg:w-2/6">
                          <img
                            src={blog_img}
                            alt="blog_img"
                            className="m-0 w-full lg:mx-2"
                          />
                        </div>
                        <div className="lg:py-6 mx-6 lg:w-4/6">
                          <h2 className="text-2xl font-bold text-[#343434]">
                            <a href="/services/web-development">
                              Web Development
                            </a>
                          </h2>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            At AJIVA INFOTECH we pride ourselves on being a
                            top-tier custom web development company. Our mission
                            is to transform your vision into reality with
                            bespoke web solutions tailored to your unique needs.
                            We specialize in creating websites that are not only
                            visually stunning but also highly functional,
                            ensuring a seamless user experience that drives
                            engagement and conversions.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            Professional Web Development Services
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            We offer a comprehensive range of professional web
                            development services designed to meet the diverse
                            needs of businesses across various industries.
                            Whether you need a simple website or a complex web
                            application, our team of experienced developers uses
                            the latest technologies and best practices to
                            deliver top-notch results. Our services include
                          </p>
                          <div></div>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            Your Gateway to the Digital Universe: WordPress
                            Development
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            WordPress isn't just a platform—it's a portal to
                            boundless possibilities. Our seasoned developers
                            harness the full potential of WordPress with
                            finesse. From elegant themes to robust plugins, we
                            craft websites that transcend mere beauty; they
                            offer an immersive experience. Our WordPress
                            solutions are seamless, scalable, and tailored
                            precisely to your brand, ensuring your online
                            presence is truly unforgettable.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            Why Choose Ajiva Infotech Pvt Ltd?
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            In a world where digital footprints profoundly
                            impact success, selecting the right partner is of
                            paramount importance. At Ajiva Infotech, we go
                            beyond simply building websites; we architect
                            experiences. Our winning formula comprises a touch
                            of innovation, a wealth of expertise, and an
                            abundance of passion. We are steadfast in our
                            commitment to bringing your digital dreams to life,
                            and our extensive portfolio of awe-inspiring
                            projects attests to that unwavering dedication. Join
                            the Digital Revolution!
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Are you prepared to transform your web aspirations
                            into awe-inspiring realities? Whether it's a marvel
                            of web development or a WordPress-powered
                            masterpiece, Ajiva Infotech Pvt Ltd serves as your
                            trusted launchpad. Reach out to us today, and
                            together, let's co-create a digital future as unique
                            as your brand! Your journey toward digital
                            excellence commences here.
                          </p>
                          <div className="flex mt-2 items-center	">
                            <a
                              href="/services/web-development"
                              className="text-red-600 font-semibold "
                            >
                              Read more
                            </a>
                            <div>
                              <a
                                href="/services/web-development"
                                className="text-black-600 text-1xl font-semibold "
                              />

                              <i class=" fa-solid fa-arrow-right-long  text-red-700 atag" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="">
                  {kk2 == true ? (
                    <div className="border-2 border-[#cccccc] mx-4 my-4">
                      <div className="mx-2 w-full lg:flex">
                        <div className="lg:w-2/6">
                          <img
                            src={blog_img}
                            alt="blog_img"
                            className="m-0 w-full lg:mx-2"
                          />
                        </div>
                        <div className="lg:py-6 mx-6 lg:w-4/6">
                          <h2 className="text-2xl font-bold text-[#343434]">
                            <a href="/services/mobile-app-development">
                              {" "}
                              Mobile App Development
                            </a>
                          </h2>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            Custom, scalable and robust web applications powered
                            by ajiva Infotech.
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            We are passionate about delivering effective design
                            solutions that are intuitive and responsive and also
                            correspond with your business objectives. Since
                            design is integral to user experience, we invest
                            largely in researching just what will work for your
                            brand depending on the industry and your target
                            audience. Our commitment towards customer
                            satisfaction reflects in our client portfolio that
                            includes several top B2B and B2C companies from
                            across the globe.
                          </p>
                          <div className="flex mt-2 items-center	">
                            <a
                              href="/services/mobile-app-development"
                              className="text-red-600 font-semibold "
                            >
                              Read more
                            </a>
                            <div>
                              <a
                                href="/services/mobile-app-development"
                                className="text-black-600 text-1xl font-semibold "
                              />

                              <i class=" fa-solid fa-arrow-right-long  text-red-700 atag" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="">
                  {kk3 == true ? (
                    <div className="border-2 border-[#cccccc] mx-4 my-4">
                      <div className="mx-2 w-full lg:flex">
                        <div className="lg:w-2/6">
                          <img
                            src={blog_img}
                            alt="blog_img"
                            className="m-0 w-full lg:mx-2"
                          />
                        </div>
                        <div className="lg:py-6 mx-6 lg:w-4/6">
                          <h2 className="text-2xl font-bold text-[#343434]">
                            <a href="/services/ecommerce-development">
                              {" "}
                              E-COMMERCE
                            </a>
                          </h2>
                          {/* <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                        Web Development
                      </h3> */}
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            E-commerce is currently experiencing a significant
                            upsurge, and Ajiva Infotech Pvt Ltd, a trailblazer
                            in the field of web development, is steadily
                            advancing by providing highly effective custom
                            eCommerce website development services over the
                            years. Our imaginative e-commerce development team
                            thrives on innovative ideas, employing them to craft
                            e-commerce solutions.
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Our eCommerce solutions epitomize top-notch features
                            and functionality, all finely tailored to meet our
                            client's unique needs. We prioritize designs that
                            deliver a clear message and compelling calls to
                            action, encouraging users to make informed choices
                            and take decisive actions.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            Services Encompassed in Our E-commerce Website
                            Development:
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Dedicated to employing client-focused strategies
                            that streamline the shopping experience, we take
                            pride in offering exceptional e-commerce development
                            services with proven, tangible results.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            Discovery and Research:
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Embarking on a journey to understand your vision, we
                            delve into what drives your business. Our team
                            provides an array of recommendations and ideas that
                            align precisely with your goals and requirements.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            Seamless User Experience:
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Our designers and developers collaborate seamlessly
                            to lay a sturdy foundation. Through meticulous
                            planning, we create robust wireframes and a strong
                            architectural framework.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            E-commerce Website Design:
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            We materialize your app idea with a unique and
                            modern interface. Utilizing top-tier images,
                            illustrations, design elements, and intuitive
                            navigation, we elevate user engagement.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            E-commerce Development:
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Leveraging advanced tools and a cutting-edge
                            technology stack, we construct responsive e-commerce
                            websites, enabling you to optimize your business
                            potential.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            Quality Assurance Testing:
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Our commitment to delivering quality results is
                            unwavering. We conduct rigorous multivariate tests,
                            continually optimizing performance to detect and
                            rectify errors or bugs.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            Optimization and Support:
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Thoroughly examining elements such as
                            call-to-action, images, button colors, and more, we
                            optimize every aspect using insights from consumer
                            behavior to drive growth. We ensure that your site
                            grows in tandem with your business and offer
                            dedicated support even after the launch.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            Why Choose Us As Your eCommerce Development Partner?
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            With an invaluable wealth of experience, our adept
                            developers craft tailored eCommerce websites that
                            effectively promote your brand, fostering success
                            and amplifying online sales. Our expert team engages
                            in detailed discussions to understand your specific
                            functionality requirements, tailoring a solution
                            that aligns seamlessly with your online brand and
                            eCommerce objectives. By attracting more visitors to
                            your website and achieving remarkable conversion
                            rates, we offer a diverse array of e-commerce
                            solutions.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            In addition to providing an exceptional user
                            experience, our proficient e-commerce website
                            development services offer unique benefits,
                            including:
                          </h3>
                          <ul className="list-disc">
                            <li className="text-sm font-normal py-2 text-[#767676]">
                              Creation of innovative, interactive, scalable, and
                              performance-oriented eCommerce stores.
                            </li>
                            <li className="text-sm font-normal py-2 text-[#767676]">
                              A highly experienced and dedicated team committed
                              to delivering projects on time.
                            </li>
                            <li className="text-sm font-normal py-2 text-[#767676]">
                              A proven track record of successfully delivering
                              hundreds of eCommerce projects.
                            </li>
                            <li className="text-sm font-normal py-2 text-[#767676]">
                              A solid reputation for delivering high-quality,
                              cost-effective solutions.
                            </li>
                          </ul>
                          <div className="flex mt-2 items-center	">
                            <a
                              href="/services/ecommerce-development"
                              className="text-red-600 font-semibold "
                            >
                              Read more
                            </a>
                            <div>
                              <a
                                href="/services/ecommerce-development"
                                className="text-black-600 text-1xl font-semibold "
                              />

                              <i class=" fa-solid fa-arrow-right-long  text-red-700 atag" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="">
                  {kk4 == true ? (
                    <div className="border-2 border-[#cccccc] mx-4 my-4">
                      <div className="mx-2 w-full lg:flex">
                        <div className="lg:w-2/6">
                          <img
                            src={blog_img}
                            alt="blog_img"
                            className="m-0 w-full lg:mx-2"
                          />
                        </div>
                        <div className="lg:py-6 mx-6 lg:w-4/6">
                          <h2 className="text-2xl font-bold text-[#343434]">
                            <a href="#">CMS Development</a>
                          </h2>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            Designing for results, designing for success-we
                            create innovative designs for your business to
                            succeed.
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            We are passionate about delivering effective design
                            solutions that are intuitive and responsive and also
                            correspond with your business objectives. Since
                            design is integral to user experience, we invest
                            largely in researching just what will work for your
                            brand depending on the industry and your target
                            audience. Our commitment towards customer
                            satisfaction reflects in our client portfolio that
                            includes several top B2B and B2C companies from
                            across the globe.
                          </p>
                          <div className="flex mt-2 items-center	">
                            <a
                              href="#"
                              className="text-red-600 font-semibold py-2"
                            >
                              Read more
                            </a>
                            <div>
                              <a
                                href="#"
                                className="text-black-600 text-1xl font-semibold "
                              />

                              <i class=" fa-solid fa-arrow-right-long  text-red-700 atag" />
                            </div>
                          </div>
                          {/* <div className="">
                            <h1 className="text-red-600 my-2 font-bold">
                              Type of Services
                            </h1>
                            <div className="lg:grid grid-cols-2 gap-3">
                              <p className="text-base font-semibold text-gray-600">
                                <i class="fa-solid fa-angle-right mx-2 text-red-600"></i>
                                Web Design
                              </p>
                              <p className="text-base font-semibold text-gray-600">
                                <i class="fa-solid fa-angle-right mx-2 text-red-600"></i>
                                Graphic Design
                              </p>
                              <p className="text-base font-semibold text-gray-600">
                                <i class="fa-solid fa-angle-right mx-2 text-red-600"></i>
                                Mobile Apps Design
                              </p>
                              <p className="text-base font-semibold text-gray-600">
                                <i class="fa-solid fa-angle-right mx-2 text-red-600"></i>
                                Branding Services
                              </p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="">
                  {kk5 == true ? (
                    <div className="border-2 border-[#cccccc] mx-4 my-4">
                      <div className="mx-2 w-full lg:flex">
                        <div className="lg:w-2/6">
                          <img
                            src={blog_img}
                            alt="blog_img"
                            className="m-0 w-full lg:mx-2"
                          />
                        </div>
                        <div className="lg:py-6 mx-6 lg:w-4/6">
                          <h2 className="text-2xl font-bold text-[#343434]">
                            <a href="/services/frontend-development">
                              Front-end Development
                            </a>
                          </h2>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Front-end web development is the process of
                            designing and building the graphical user interface
                            (GUI) of any website. Sometimes called client-side
                            development, front-end development refers to the
                            development of the parts of a website the user
                            interacts with, such as buttons and text boxes.
                            Welcome to Ajiva Infotech, where we turn code into
                            captivating experiences! Our frontend development
                            maestros utilize the prowess of React.js,
                            Angular.js, JavaScript, REST, HTML, CSS, and the
                            magic of responsive design to breathe life into your
                            web applications.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            The Frontend Tech Symphony We Play:
                          </h3>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            1. React.js - Dynamic User Experiences:
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Brace yourself for React.js magic! It's not just a
                            library; it's a revolution. We employ React.js to
                            build user interfaces that breathe, adapting and
                            reacting to user interactions in real-time. Your
                            users won't just browse; they'll experience.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            2. Angular.js - Crafting Structured Marvels:
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Angular.js is our forte for building structured,
                            scalable, and feature-rich web applications. The
                            two-way data binding and modularity it offers
                            empower us to create interfaces that define a new
                            standard in user engagement.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            3. JavaScript - The Pulse of Interactivity:
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            JavaScript is where the interactivity unfolds. We
                            infuse life into your application with interactive
                            elements, smooth animations, and real-time updates,
                            making sure every click feels natural and
                            delightful.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            4. REST - Seamless Communication:
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            RESTful APIs serve as the language of interaction
                            between frontend and backend. We harness the power
                            of REST to ensure smooth, efficient communication,
                            delivering an uninterrupted user journey.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            5. HTML & CSS - The Aesthetics and Structure:
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            HTML provides the structure, while CSS adds the
                            style and beauty. We meticulously blend these
                            elements to create visually stunning and intuitively
                            structured interfaces that leave a lasting
                            impression.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            6. Responsive Design - Beauty on Every Screen:
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Every device is a canvas. With responsive design, we
                            ensure that your application looks stunning and
                            functions flawlessly on devices of all shapes and
                            sizes, providing an exceptional user experience no
                            matter where your audience is. Why Choose Ajiva
                            Infotech for Frontend Marvels? Innovative Designs:
                            We go beyond standard designs, tailoring every
                            element to fit your brand identity and engage your
                            audience at first glance. Performance Excellence:
                            Speed matters. We optimize your frontend to load
                            blazingly fast, setting the stage for an immersive
                            user experience. Cross-Device Compatibility: Your
                            application should be a seamless experience on any
                            device. Our frontends are crafted to perform
                            effortlessly, whether on a desktop, tablet, or
                            smartphone. Join us at Ajiva Infotech, and let's
                            transform your ideas into a frontend wonderland.
                            Together, we'll build a digital presence that users
                            will love to engage with.
                          </p>
                          <div className="flex mt-2 items-center	">
                            <a
                              href="/services/frontend-development"
                              className="text-red-600 font-semibold py-2"
                            >
                              Read more
                            </a>
                            <div>
                              <a
                                href="/services/frontend-development"
                                className="text-black-600 text-1xl font-semibold "
                              />

                              <i class=" fa-solid fa-arrow-right-long  text-red-700 atag" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="">
                  {kk6 == true ? (
                    <div className="border-2 border-[#cccccc] mx-4 my-4">
                      <div className="mx-2 w-full lg:flex">
                        <div className="lg:w-2/6">
                          <img
                            src={blog_img}
                            alt="blog_img"
                            className="m-0 w-full lg:mx-2"
                          />
                        </div>
                        <div className="lg:py-6 mx-6 lg:w-4/6">
                          <h2 className="text-2xl font-bold text-[#343434]">
                            <a href="#"> Backend Development</a>
                          </h2>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            Backend Development with PHP & Laravel:
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Unlock the potential of modern web development with
                            PHP and Laravel. PHP, the cornerstone of web
                            scripting, seamlessly combines with the power of
                            Laravel, a leading PHP framework, to form the
                            perfect duo for crafting dynamic, efficient, and
                            secure web solutions.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            Why Choose PHP?
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Versatility: PHP is the chameleon of web
                            development, effortlessly adapting to your project
                            needs, whether they involve simple scripts or
                            complex applications.
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Speed: Swift PHP development cycles ensure your
                            project is up and running in no time, giving you a
                            competitive edge in the digital world.
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Community: Benefit from an expansive PHP community
                            that ensures a vast library of resources and
                            unparalleled support. With PHP, you're never on this
                            journey alone.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            Why Laravel Shines:
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Elegant Syntax: Laravel simplifies complex tasks
                            with its expressive and elegant syntax, making
                            coding a delightful experience and boosting
                            development efficiency.
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Robust Features: Laravel offers a wide range of
                            built-in features for accelerated development, from
                            seamless authentication to efficient caching. This
                            feature richness expedites the development process,
                            letting you focus on building exceptional
                            applications.
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Scalability: Easily scale your applications as your
                            user base and traffic grow, thanks to Laravel's
                            flexible architecture. Your applications can
                            seamlessly handle increased demand, ensuring a
                            smooth user experience even during rapid growth.
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Join the PHP and Laravel revolution and witness your
                            web projects thrive. Let's partner to build a
                            dynamic digital future that sets new standards in
                            the world of web development!
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            Node.js Development:
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Unlock the potential of modern web development with
                            Node.js. Node.js, known for its lightning-fast and
                            scalable nature, is a game-changer in building
                            real-time, efficient, and scalable web solutions.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            Why Choose Node.js?
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Real-Time Efficiency: Node.js thrives in real-time
                            applications, providing lightning-fast
                            responsiveness and optimal performance, making it
                            ideal for chat applications, collaborative tools,
                            and more.
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Scalability: Scale your applications effortlessly to
                            handle a growing user base and increasing traffic.
                            Node.js' non-blocking I/O model allows for handling
                            a large number of concurrent connections seamlessly.
                          </p>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            Microservices Architecture: Node.js facilitates a
                            microservices-based architecture, allowing you to
                            build and deploy independent, scalable services that
                            streamline development and enhance flexibility.
                          </p>
                          <h3 className="text-lg font-semibold text-[#4b4b4b] py-1">
                            Why Partner with Ajiva Infotech for Node.js
                            Development?
                          </h3>
                          <p className="text-sm font-normal py-2 text-[#767676]">
                            At Ajiva Infotech, we're passionate about leveraging
                            Node.js to create real-time applications and
                            scalable backend systems. Join the Node.js
                            revolution and collaborate with us to build a
                            dynamic digital future that exceeds expectations.
                          </p>
                          {/* 
                          <div className="enhanceflexibility">
                            {" "}
                            <button> */}
                          <div className="flex mt-5">
                            <a href="#" className="p-1 text-red-700">
                              Read more
                            </a>
                            <div>
                              <a
                                href="#"
                                className="text-black-600 text-1xl font-semibold "
                              />

                              <i class=" fa-solid fa-arrow-right-long pt-[10px] text-red-700 atag" />
                            </div>
                          </div>
                          {/* </button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Services;

import React, { useState } from "react";
import Helmet from "react-helmet";
import logo from "../../../img/logo192.png";
function TextReplacementTool() {
  const [searchText, setSearchText] = useState("");
  const [replaceText, setReplaceText] = useState("");
  const [inputContent, setInputContent] = useState("");
  const [replacedContent, setReplacedContent] = useState("");
  const [showPreview, setShowPreview] = useState(false);

  const performReplacement = () => {
    if (!searchText.trim()) {
      alert("Please enter a search text.");
      return;
    }

    const updatedContent = inputContent.replaceAll(searchText, replaceText);
    setReplacedContent(updatedContent);
    setShowPreview(true);
  };

  const toggleView = () => {
    setShowPreview(!showPreview);
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="" />
        <meta
          name="title"
          content="Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" coding validation tools, programming tools, code checker, code error finding tool, code beautify tool, code scanning tool"
        />
        <meta
          name="description"
          content=" Discover top coding validation tools to ensure error-free, high-quality code. Learn how tools like HTML Preview, Minyfiy to UnMinyfiy, Json Validator, Validate CSS, String Replace, Webhook Test can streamline your development workflow and enforce coding standards. "
        />
        {/* <link rel="apple-touch-icon" href={logo} /> */}
        <title>
          Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech
        </title>
      </Helmet>
      <div className="my-20">
        <div className="text-replacement-container p-6 border-2 mx-auto bg-gray-100 rounded-lg shadow-md w-[80%]">
          <h2 className="text-2xl font-bold mb-4 text-center">
            Text Replacement Tool
          </h2>

          <div className="input-section mb-4 flex">
            <div className="lg:w-1/2 mx-5">
              <label htmlFor="searchText" className="block mb-1">
                Search Text:
              </label>
              <input
                type="text"
                id="searchText"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Enter text to search"
                className="input-field px-4 py-2 w-full rounded border border-gray-300 focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="lg:w-1/2 mx-5">
              <label htmlFor="replaceText" className="block mb-1">
                Replace Text:
              </label>
              <input
                type="text"
                id="replaceText"
                value={replaceText}
                onChange={(e) => setReplaceText(e.target.value)}
                placeholder="Enter replacement text"
                className="input-field px-4 py-2 w-full rounded border border-gray-300 focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>

          <div className="mx-5">
            <label htmlFor="inputContent" className="block mb-1 font-bold">
              Input Text or HTML:
            </label>
            <textarea
              id="inputContent"
              value={inputContent}
              onChange={(e) => setInputContent(e.target.value)}
              placeholder="Paste your text or HTML here"
              className="input-textarea px-4 py-2 w-full rounded border border-gray-300 focus:outline-none focus:border-blue-500"
            ></textarea>
          </div>

          <div className="mx-5">
            <button
              onClick={performReplacement}
              className="replace-button w-full py-2 mt-4 bg-blue-500 text-white font-bold rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              Replace
            </button>
          </div>

          {showPreview ? (
            <div className="output-panel mt-6 mx-5">
              <h3 className="panel-heading text-lg font-bold mb-2">Preview</h3>
              <div className="replaced-text border border-gray-300 rounded p-4">
                {replacedContent}
              </div>
              <button
                onClick={toggleView}
                className="toggle-button mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              >
                Show Source
              </button>
            </div>
          ) : (
            <div className="output-panel mt-6 mx-5">
              <h3 className="panel-heading text-lg font-bold mb-2">
                Source Code
              </h3>
              <pre className="source-code border border-gray-300 rounded p-4">
                {replacedContent}
              </pre>
              <button
                onClick={toggleView}
                className="toggle-button mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              >
                Show Preview
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default TextReplacementTool;

// Login.js
import React, { useState, useRef, useEffect } from "react";
import api from "../../Api";
import WpmantinanceLogo from "../../img/wpmantinance/wp-mantinance-logo.webp";
import { NavLink, useNavigate } from "react-router-dom";
import { usePopup } from "../Common/PopupContext";
import "../../App.css";
import { encryptData } from "../../helpers/encryption";
import ForgotPassword from "./ForgotPassword";
import CustomToastContainer from "../Common/CustomToastContainer";
import VisitorTracker from "../Common/VisitorTracker.js";
import loginbg from "../../img/loginbg.webp";
import FeatherIcon from "feather-icons-react";
const Login = ({ redirect_url }) => {
  const [email, setEmail] = useState("");
  const [expand, setExpand] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const logoRef = useRef(null);
  const [validationMessage, setValidationMessage] = useState("");
  const { openPopup } = usePopup();
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMessage, setIsMessage] = useState(null);
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useNavigate();
  const toastRef = useRef();
  const handleKeepMeLoggedInChange = (e) => {
    setKeepMeLoggedIn(e.target.checked);
  };

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        } else {
          entry.target.classList.remove("visible");
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.5,
    });

    if (logoRef.current) observer.observe(logoRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        console.log("Is logo in viewport?", entry.isIntersecting); // Debugging visibility
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (logoRef.current) {
      observer.observe(logoRef.current);
    }

    return () => {
      if (logoRef.current) {
        observer.unobserve(logoRef.current);
      }
    };
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (!email) {
      setValidationMessage("Email is required.");
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setValidationMessage("Email is invalid.");
      return;
    }

    if (!password) {
      setValidationMessage("Password is required.");
      return;
    }

    setLoading(true);

    try {
      const userData = { phoneoremail: email, password };
      const response = await api.Login(userData);
      console.log(response, "response");

      if (response.login === true) {
        toastRef.current.addToast("Login Successfully", "success");

        const { access_token, refresh_token, userInfo } = response.sessdata;

        // Encrypt and store the user data
        const encryptedUserData = encryptData({
          ...response.sessdata,
          user: userInfo, // Setting user details in the response
        });

        const expirationDate = new Date();
        if (keepMeLoggedIn) {
          expirationDate.setFullYear(expirationDate.getFullYear() + 1); // 1 year for "Keep Me Logged In"

          // Store in localStorage
          localStorage.setItem("user", encryptedUserData);
          localStorage.setItem("accessToken", access_token);
          localStorage.setItem("refreshToken", refresh_token);

          // Store in cookies
          document.cookie = `user=${encodeURIComponent(encryptedUserData)}; expires=${expirationDate.toUTCString()}; path=/`;
          document.cookie = `accessToken=${access_token}; expires=${expirationDate.toUTCString()}; path=/`;
          document.cookie = `refreshToken=${refresh_token}; expires=${expirationDate.toUTCString()}; path=/`;
        } else {
          expirationDate.setMinutes(expirationDate.getMinutes() + 30); // 30 minutes for session

          // Store in sessionStorage
          sessionStorage.setItem("user", encryptedUserData);
          sessionStorage.setItem("accessToken", access_token);
          sessionStorage.setItem("refreshToken", refresh_token);

          // Store in cookies
          document.cookie = `user=${encodeURIComponent(encryptedUserData)}; expires=${expirationDate.toUTCString()}; path=/`;
          document.cookie = `accessToken=${access_token}; expires=${expirationDate.toUTCString()}; path=/`;
          document.cookie = `refreshToken=${refresh_token}; expires=${expirationDate.toUTCString()}; path=/`;
        }

        // Redirect logic
        setTimeout(() => {
          const redirectData = localStorage.getItem("redirect_url");
          if (redirectData) {
            setLoading(false);
            window.location.href = redirectData;
            localStorage.removeItem("redirect_url");
          } else if (redirect_url) {
            setLoading(false);
            window.location.href = redirect_url;
          } else {
            setLoading(false);
            window.location.href = "/dashboard";
          }
        }, 2000);
      } else {
        console.error(
          "Login failed: Unexpected response status",
          response.status
        );
        toastRef.current.addToast(response.data.msg, "error");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error during login:", error);
      toastRef.current.addToast(
        error.response?.data?.msg || "Login failed",
        "error"
      );
      setLoading(false);
    }
  };

  const handleClosePopup = () => {
    openPopup();
  };
  const socialLogin = async (e) => {
    e.preventDefault();
    const googleAuthUrl = () => {
      const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID; // process.env.GOOGLE_CLIENT_ID;
      const googleCallbackUrl = process.env.REACT_APP_GOOGLE_CALLBACK_URL; // process.env.REACT_APP_GOOGLE_CALLBACK_URL;
      const scope = "profile email";
      const redirectUri = encodeURIComponent(googleCallbackUrl);
      const state = JSON.stringify({
        role: "applicant",
      });
      return `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${googleClientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${encodeURIComponent(
        state
      )}&access_type=offline&prompt=consent`;
    };
    window.location.href = googleAuthUrl();
  };

  const handleforgot = () => {
    setLoading(true);

    if (redirect_url != undefined) {
      const popup = (
        <ForgotPassword
          onClose={() => handleClosePopup("Popup closed")}
          redirect_url={redirect_url}
        />
      );
      openPopup(popup);
    } else {
      history("./forgotpassword");
      window.location.reload();
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handlelogoclick = () => {
    navigate("/services/wordpress-maintenance");
    // window.location.reload();
  };
  return (
    <div className="ajivaloginpage">
      {/* <div className="login_inform login_inform-page min-h-screen	 flex justify-center  ">
        <div className="xl:w-1/2 lg:2/3  md:w-11/12 md:flex login_inform-inner m-auto md:m-auto md:mt-20 rounded-xl justify-center container">
          <div
            className="lg:w-full  md:w-full md:flex justify-center  mx-5 "
            style={{
              boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <div
              className="lg:w-1/2 md:w-1/2 bg-white py-20 Welcome_To_section"
              // style={{ backgroundColor: "#ffa500" }}
            >
              <div className="md:py-20">
                <h1
                  className="text-3xl font-bold text-center mt-5"
                  style={{ color: "#003366" }}
                >
                  Welcome To Login{" "}
                </h1>
                <h2 className="text-center mt-3">Don't have an account ?</h2>
                <h2 className="text-center mt-3">
                  <a href="/register">
                    <button
                      className="py-3 px-8 rounded-full text-white"
                      style={{ backgroundColor: "#003366" }}
                    >
                      Sign Up
                    </button>
                  </a>{" "}
                </h2>
              </div>
            </div>
            <div className="  form_upto  lg:w-1/2 md:w-1/2 bg-white">
              <div className=" rounded px-6 pt-4 pb-6 mb-4">
                <div className="mb-1 text-center">
                  <div className="text-center HirePros-login-heading ">
                    <h2 className="pt-8  ">Hi, Welcome Back !</h2>
                  </div>
                </div>
                {isMessage && (
                  <button className="btn-loginmessage" type="text" danger>
                    {isMessage}
                  </button>
                )}
                <form
                  className="login-form-section animated-form"
                  onSubmit={handleSubmit}
                >
                  <div className="mb-2 form-group relative">
                    <input
                      className="  border rounded w-full py-2 px-3 text-gray-700  "
                      type="text"
                      id="email"
                      placeholder=""
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="email" className="Email-label">
                      Email
                    </label>
                  </div>
                  <div className=" mb-2 form-group relative">
                    <input
                      id="password"
                      className="border rounded w-full py-2 px-3 text-gray-700 mb-3"
                      type={showPassword ? "text" : "password"}
                      placeholder=""
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label htmlFor="password" className="password-label">
                      Password
                    </label>
                    <span
                      onClick={togglePasswordVisibility}
                      className="absolute right-3 top-6  transform -translate-y-1/2 cursor-pointer"
                    >
                      {showPassword ? (
                        <FeatherIcon
                          icon="eye-off"
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 text-gray-500"
                          viewBox="0 0 576 512"
                        >
                          <path
                            fill="currentColor"
                            d="M572.52 241.4C518.08 135.19 407.81 64 288 64 168.19 64 57.92 135.19 3.48 241.4a48.35 48.35 0 000 29.2C57.92 376.81 168.19 448 288 448c119.81 0 230.08-71.19 284.52-177.4a48.35 48.35 0 000-29.2zM288 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144zm0-240a96 96 0 1096 96 96 96 0 00-96-96z"
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                  <div className="mb-6 flex items-center justify-between forgot-password-section-font">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600"
                        checked={keepMeLoggedIn}
                        onChange={handleKeepMeLoggedInChange}
                      />
                      <span className="ml-2 text-gray-700">
                        Keep me logged in
                      </span>
                    </label>
                    <NavLink
                      className="forgot-pass-link color"
                      to="/forgotpassword"
                      onClick={() => handleforgot()}
                    >
                      Forgot Password?
                    </NavLink>
                  </div>
                  {validationMessage && (
                    <div className="mb-4 text-red-500">{validationMessage}</div>
                  )}
                  <div>{isMessage != "null" ? isMessage : ""}</div>
                  <div className="">
                    <button
                      className="login-form-btn bg-[#003366] text-white py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                      type="submit"
                    >
                      {isLoading ? "Loading..." : "Log in"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>{" "} */}
      <section className="ajv-maintenance-section">
        <div className="ajv-maintenance-page-inner">
          <div className="page-template-page-tldr_preview h-screen">
            <div
              className={`WpmantinanceLogo-style ${
                isVisible ? "animate-slide" : ""
              }`}
              ref={logoRef}
            >
              <div
                className={`WpmantinanceLogo-style-inner ${
                  expand ? "expand" : ""
                }`}
                onClick={() => setExpand(true)}
              >
                {" "}
                <img
                  src={WpmantinanceLogo}
                  alt="company logo"
                  height="100%"
                  width="100%"
                  className="cursor-pointer"
                  onClick={(e) => handlelogoclick()}
                ></img>
              </div>
            </div>
            <div className="container m-auto py-20 ">
              {" "}
              <div className=" items-center py-8 w-full h-full">
                <div className="">
                  <div className="Membership-chackout">
                    <h1 className=" mb-4 text-white">
                      Hi,<span className="checkoutmembership"> Welcome</span>{" "}
                      Back!
                    </h1>
                  </div>
                </div>
                <div
                  className="flex flex-col md:flex-row items-center justify-center relative p-6"
                  style={{ zIndex: "9999" }}
                >
                  {/* Left Section */}
                  <div className="w-full md:w-1/2 flex justify-center items-center">
                    <div className="text-center">
                      <img
                        src={loginbg} // Replace this with a relevant image link
                        alt="Illustration"
                        className="w-full max-w-md mx-auto"
                      />
                    </div>
                  </div>

                  {/* Right Section */}
                  <div className="w-full md:w-1/3 px-10 py-28  loginform h-5/6">
                    <form
                      className="login-form-section animated-form"
                      onSubmit={handleSubmit}
                    >
                      <div className="mb-2 form-group relative">
                        <input
                          className="  border rounded w-full py-2 px-3 text-gray-700  "
                          type="text"
                          id="email"
                          placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {/* <label htmlFor="email" className="Email-label">
                          Email
                        </label> */}
                      </div>
                      <div className=" mb-2 form-group relative">
                        <input
                          id="password"
                          className="border rounded w-full py-2 px-3 text-gray-700 mb-3"
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        {/* <label htmlFor="password" className="password-label">
                          Password
                        </label> */}
                        <span
                          onClick={togglePasswordVisibility}
                          className="absolute right-3 top-6  transform -translate-y-1/2 cursor-pointer"
                        >
                          {showPassword ? (
                            <FeatherIcon
                              icon="eye-off"
                              style={{ width: "20px", height: "20px" }}
                            />
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 text-white"
                              viewBox="0 0 576 512"
                            >
                              <path
                                fill="currentColor"
                                d="M572.52 241.4C518.08 135.19 407.81 64 288 64 168.19 64 57.92 135.19 3.48 241.4a48.35 48.35 0 000 29.2C57.92 376.81 168.19 448 288 448c119.81 0 230.08-71.19 284.52-177.4a48.35 48.35 0 000-29.2zM288 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144zm0-240a96 96 0 1096 96 96 96 0 00-96-96z"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                      <div className="mb-6 flex items-center justify-between forgot-password-section-font">
                        <label className="inline-flex items-center">
                          <input
                            type="checkbox"
                            className="form-checkbox h-4 w-4 text-blue-600"
                            checked={keepMeLoggedIn}
                            onChange={handleKeepMeLoggedInChange}
                          />
                          <span className="ml-2 text-white">
                            Keep me logged in
                          </span>
                        </label>
                        <NavLink
                          className="forgot-pass-link color italic"
                          to="/forgotpassword"
                          onClick={() => handleforgot()}
                        >
                          Forgot Password?
                        </NavLink>
                      </div>
                      {validationMessage && (
                        <div className="mb-4 text-red-600 text-lg font-semibold ">
                          {validationMessage}
                        </div>
                      )}
                      <div>{isMessage != "null" ? isMessage : ""}</div>
                      <div className="">
                        <button
                          className="login-form-btn bg-[#003366] text-white py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                          type="submit"
                        >
                          {isLoading ? "Loading..." : "Log in"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Sphere Section */}
            <div className="sphere_section">
              <div className="spherebubble-sphere_1 spherebubble"></div>
              <div className="spherebubble-sphere_2 spherebubble"></div>
              <div className="spherebubble-sphere_3 spherebubble"></div>
              <div className="spherebubble-sphere_4 spherebubble"></div>
              <div className="spherebubble-sphere_6 spherebubble"></div>
              <div className="spherebubble-sphere_7 spherebubble"></div>
              <div className="spherebubble-sphere_8 spherebubble"></div>
            </div>
          </div>
        </div>
      </section>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <VisitorTracker />
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default Login;

import React from "react";
import Helmet from "react-helmet";
import career from "../../img/background_img/Career.jpg";
import logo from "../../img/logo192.png";
const Career = () => {
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // You can access the selected file here and perform actions like uploading it to a server or processing it.
      console.log("Selected file:", selectedFile);
    }
  };
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="title"
          content="Latest Digital transformation, Web & Mobile Technology | AjivaInfotech Blog"
        />
        <title>
          Latest Digital transformation, Web & Mobile Technology | AjivaInfotech
          Blog
        </title>
        <meta name="robots" content="index, follow"></meta>
        <meta
          content="Official AjivaInfotech Blogs & News- Discover the latest insights, trends, and best practices in software development, testing, and quality assurance."
          name="description"
        ></meta>
        <meta
          name="description"
          content="Official AjivaInfotech Blogs & News- Discover the latest insights, trends, and best practices in software development, testing, and quality assurance."
        />
        <link rel="apple-touch-icon" href={logo} />
      </Helmet>

      <section className="container-fluid m-auto">
        <div className="w-full">
          <img className="w-full" src={career} alt="career" />
          {/* <img
            src={career_1}
            alt="career"
            className="lg:hidden md:hidden block"
          /> */}
        </div>
      </section>
      <div className="container m-auto careermaindiv py-4">
        <section>
          <div className="w-full lg:flex justify-space lg:m-6 pt-6">
            <div className="lg:w-1/2">
              <h2 className="mx-4 text-3xl font-bold text-[#343434]">
                Job Openings
              </h2>
            </div>
            <div className="lg:w-1/2">
              <h2 className="mx-4 text-3xl font-bold text-[#343434]">
                Apply Here
              </h2>
            </div>
          </div>
          <div className=""></div>
          <div className="w-full lg:flex my-6 careerdiv">
            <div className="lg:w-1/2">
              <div class="space-y-4">
                <details class="group rounded-lg    [&_summary::-webkit-details-marker]:block">
                  <summary class="flex cursor-pointer items-center px-6 py-2 gap-1.5 text-gray-900 bg-gray-100">
                    <span class="relative h-5 w-5 shrink-0 mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="absolute inset-0 h-5 w-5 opacity-100 group-open:opacity-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="absolute inset-0 h-5 w-5 opacity-0 group-open:opacity-100"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </span>
                    <h2 class="font-medium text-xl p-2">PHP Developer</h2>
                  </summary>
                  <div className="px-5">
                    <h2 className="text-base font-semibold py-2 mt-2 text-[#3c3c3c] px-3">
                      Responsibilities and Duties
                    </h2>
                    <ul className="list-disc px-5" style={{ color: "#686868" }}>
                      <li>
                        <p class=" leading-relaxed text-[#686868] text-sm">
                          Provide design concepts and develop, create and
                          integrate the website layouts/user interfaces.
                        </p>
                      </li>
                      <li>
                        <p class="mt-4 leading-relaxed text-[#686868]">
                          Create responsive and pixel perfect web designs and
                          components from the requirements stage through
                          production deployment.
                        </p>
                      </li>
                      <li>
                        <p class="mt-4 leading-relaxed text-[#686868]">
                          Create wire frames, storyboards, user flows, process
                          flows, mock ups and site maps to communicate
                          interaction and design ideas.
                        </p>
                      </li>
                      <li>
                        <p class="mt-4 leading-relaxed text-[#686868]">
                          Help in integration of UI with the backend.
                        </p>
                      </li>
                      <li>
                        <p class="mt-4 leading-relaxed text-[#686868]">
                          Design, modify, develop, write and implement HTML/CSS
                          code and components.
                        </p>
                      </li>
                      <li>
                        <p class="mt-4 leading-relaxed text-[#686868]">
                          Work closely with web developers to ensure designs are
                          implemented correctly.
                        </p>
                      </li>
                      <li>
                        <p class="mt-4 leading-relaxed text-[#686868]">
                          Ensure web pages and other digital assets work across
                          all browsers and devices.
                        </p>
                      </li>
                      <li>
                        <p class="mt-4 leading-relaxed text-[#686868]">
                          Maintain consistent on-brand creative within a
                          fast-paced and collaborative environment.
                        </p>
                      </li>
                      <li>
                        <p class="mt-4 leading-relaxed text-[#686868]">
                          Demonstrate a high level of mastery with CSS and the
                          ability to execute on complex designs, as well as a
                          focused understanding of JavaScript.
                        </p>
                      </li>
                    </ul>
                    <div className="mt-5">
                      <h2 className="text-base font-bold text-[#3c3c3c] my-2">
                        Desired Profile: Please refer to the Job description
                        above
                      </h2>

                      <div class="overflow-x-auto">
                        <table class="min-w-full divide-y-2 divide-gray-200  text-sm">
                          <tbody class="divide-y divide-gray-200">
                            <tr>
                              <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                                Experience
                              </td>
                              <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                                1 Year - 2 Years
                              </td>
                            </tr>

                            <tr>
                              <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                                Industry Type
                              </td>
                              <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                                Software Services
                              </td>
                            </tr>

                            <tr>
                              <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                                Role:
                              </td>
                              <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                                PHP Developer
                              </td>
                            </tr>
                            <tr>
                              <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                                Email-id:
                              </td>
                              <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                                hr@ajivainfotech.com
                              </td>
                            </tr>
                            <tr>
                              <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                                Telephone
                              </td>
                              <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                                +91-0000000000
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </details>

                <details class="group rounded-lg  [&_summary::-webkit-details-marker]:hidden">
                  <summary class="flex cursor-pointer items-center gap-1.5 text-gray-900 bg-gray-100 px-6 py-4">
                    <span class="relative h-5 w-5 shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="absolute inset-0 opacity-100 group-open:opacity-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="absolute inset-0 opacity-0 group-open:opacity-100"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </span>
                    <h2 class="font-medium text-xl px-3 text-[#4b4b4b]">
                      Front-end web development
                    </h2>
                  </summary>

                  <div className="px-5">
                    <h2 className="text-base font-semibold py-2 mt-2 text-[#3c3c3c] px-3">
                      Responsibilities and Duties
                    </h2>
                    <ul className="list-disc px-5" style={{ color: "#686868" }}>
                      <li>
                        <p class=" leading-relaxed text-[#686868] text-sm">
                          Provide design concepts and develop, create and
                          integrate the website layouts/user interfaces.
                        </p>
                      </li>
                      <li>
                        <p class="mt-4 leading-relaxed text-[#686868]">
                          Create responsive and pixel perfect web designs and
                          components from the requirements stage through
                          production deployment.
                        </p>
                      </li>
                      <li>
                        <p class="mt-4 leading-relaxed text-[#686868]">
                          Create wire frames, storyboards, user flows, process
                          flows, mock ups and site maps to communicate
                          interaction and design ideas.
                        </p>
                      </li>
                      <li>
                        <p class="mt-4 leading-relaxed text-[#686868]">
                          Help in integration of UI with the backend.
                        </p>
                      </li>
                      <li>
                        <p class="mt-4 leading-relaxed text-[#686868]">
                          Design, modify, develop, write and implement HTML/CSS
                          code and components.
                        </p>
                      </li>
                      <li>
                        <p class="mt-4 leading-relaxed text-[#686868]">
                          Work closely with web developers to ensure designs are
                          implemented correctly.
                        </p>
                      </li>
                      <li>
                        <p class="mt-4 leading-relaxed text-[#686868]">
                          Ensure web pages and other digital assets work across
                          all browsers and devices.
                        </p>
                      </li>
                      <li>
                        <p class="mt-4 leading-relaxed text-[#686868]">
                          Maintain consistent on-brand creative within a
                          fast-paced and collaborative environment.
                        </p>
                      </li>
                      <li>
                        <p class="mt-4 leading-relaxed text-[#686868]">
                          Demonstrate a high level of mastery with CSS and the
                          ability to execute on complex designs, as well as a
                          focused understanding of JavaScript.
                        </p>
                      </li>
                    </ul>
                    <div className="mt-5">
                      <h2 className="text-base font-bold text-[#3c3c3c] my-2">
                        Desired Profile: Please refer to the Job description
                        above
                      </h2>

                      <div class="overflow-x-auto">
                        <table class="min-w-full divide-y-2 divide-gray-200  text-sm">
                          <tbody class="divide-y divide-gray-200">
                            <tr>
                              <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                                Experience
                              </td>
                              <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                                1 Year - 2 Years
                              </td>
                            </tr>

                            <tr>
                              <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                                Industry Type
                              </td>
                              <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                                Software Services
                              </td>
                            </tr>

                            <tr>
                              <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                                Role:
                              </td>
                              <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                                Front-end web development
                              </td>
                            </tr>
                            <tr>
                              <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                                Email-id:
                              </td>
                              <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                                hr@ajivainfotech.com
                              </td>
                            </tr>
                            <tr>
                              <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                                Telephone
                              </td>
                              <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                                +91-0000000000
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </details>
              </div>
              <details class="group rounded-lg my-4 [&_summary::-webkit-details-marker]:hidden">
                <summary class="flex cursor-pointer items-center gap-1.5 text-gray-900 bg-gray-100 px-6 py-4">
                  <span class="relative h-5 w-5 shrink-0 mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="absolute inset-0 h-5 w-5 opacity-100 group-open:opacity-0"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="absolute inset-0 h-5 w-5 opacity-0 group-open:opacity-100"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </span>
                  <h2 class="font-medium text-xl px-3">Web Development</h2>
                </summary>

                <div className="px-5">
                  <h2 className="text-base font-semibold py-2 mt-2 text-[#3c3c3c] px-3">
                    Responsibilities and Duties
                  </h2>
                  <ul className="list-disc px-5" style={{ color: "#686868" }}>
                    <li>
                      <p class=" leading-relaxed text-[#686868] text-sm">
                        Provide design concepts and develop, create and
                        integrate the website layouts/user interfaces.
                      </p>
                    </li>
                    <li>
                      <p class="mt-4 leading-relaxed text-[#686868]">
                        Create responsive and pixel perfect web designs and
                        components from the requirements stage through
                        production deployment.
                      </p>
                    </li>
                    <li>
                      <p class="mt-4 leading-relaxed text-[#686868]">
                        Create wire frames, storyboards, user flows, process
                        flows, mock ups and site maps to communicate interaction
                        and design ideas.
                      </p>
                    </li>
                    <li>
                      <p class="mt-4 leading-relaxed text-[#686868]">
                        Help in integration of UI with the backend.
                      </p>
                    </li>
                    <li>
                      <p class="mt-4 leading-relaxed text-[#686868]">
                        Design, modify, develop, write and implement HTML/CSS
                        code and components.
                      </p>
                    </li>
                    <li>
                      <p class="mt-4 leading-relaxed text-[#686868]">
                        Work closely with web developers to ensure designs are
                        implemented correctly.
                      </p>
                    </li>
                    <li>
                      <p class="mt-4 leading-relaxed text-[#686868]">
                        Ensure web pages and other digital assets work across
                        all browsers and devices.
                      </p>
                    </li>
                    <li>
                      <p class="mt-4 leading-relaxed text-[#686868]">
                        Maintain consistent on-brand creative within a
                        fast-paced and collaborative environment.
                      </p>
                    </li>
                    <li>
                      <p class="mt-4 leading-relaxed text-[#686868]">
                        Demonstrate a high level of mastery with CSS and the
                        ability to execute on complex designs, as well as a
                        focused understanding of JavaScript.
                      </p>
                    </li>
                  </ul>
                  <div className="mt-5">
                    <h2 className="text-base font-bold text-[#3c3c3c] my-2">
                      Desired Profile: Please refer to the Job description above
                    </h2>

                    <div class="overflow-x-auto">
                      <table class="min-w-full divide-y-2 divide-gray-200  text-sm">
                        <tbody class="divide-y divide-gray-200">
                          <tr>
                            <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                              Experience
                            </td>
                            <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                              1 Year - 2 Years
                            </td>
                          </tr>

                          <tr>
                            <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                              Industry Type
                            </td>
                            <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                              Software Services
                            </td>
                          </tr>

                          <tr>
                            <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                              Role:
                            </td>
                            <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                              Web Development
                            </td>
                          </tr>
                          <tr>
                            <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                              Email-id:
                            </td>
                            <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                              hr@ajivainfotech.com
                            </td>
                          </tr>
                          <tr>
                            <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                              Telephone
                            </td>
                            <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                              +91-0000000000
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </details>

              <details class="group rounded-lg [&_summary::-webkit-details-marker]:hidden">
                <summary class="flex cursor-pointer items-center gap-1.5 text-gray-900 bg-gray-100 px-6 py-4">
                  <span class="relative h-5 w-5 shrink-0 mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="absolute inset-0 opacity-100 group-open:opacity-0"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="absolute inset-0 opacity-0 group-open:opacity-100"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </span>
                  <h2 class="font-medium text-xl px-3 text-[#4b4b4b]">
                    Graphic Designer
                  </h2>
                </summary>
                <div className="px-5">
                  <h2 className="text-base font-semibold py-2 mt-2 text-[#3c3c3c] px-3">
                    Responsibilities and Duties
                  </h2>
                  <ul className="list-disc px-5" style={{ color: "#686868" }}>
                    <li>
                      <p class=" leading-relaxed text-[#686868] text-sm">
                        Provide design concepts and develop, create and
                        integrate the website layouts/user interfaces.
                      </p>
                    </li>
                    <li>
                      <p class="mt-4 leading-relaxed text-[#686868]">
                        Create responsive and pixel perfect web designs and
                        components from the requirements stage through
                        production deployment.
                      </p>
                    </li>
                    <li>
                      <p class="mt-4 leading-relaxed text-[#686868]">
                        Create wire frames, storyboards, user flows, process
                        flows, mock ups and site maps to communicate interaction
                        and design ideas.
                      </p>
                    </li>
                    <li>
                      <p class="mt-4 leading-relaxed text-[#686868]">
                        Help in integration of UI with the backend.
                      </p>
                    </li>
                    <li>
                      <p class="mt-4 leading-relaxed text-[#686868]">
                        Design, modify, develop, write and implement HTML/CSS
                        code and components.
                      </p>
                    </li>
                    <li>
                      <p class="mt-4 leading-relaxed text-[#686868]">
                        Work closely with web developers to ensure designs are
                        implemented correctly.
                      </p>
                    </li>
                    <li>
                      <p class="mt-4 leading-relaxed text-[#686868]">
                        Ensure web pages and other digital assets work across
                        all browsers and devices.
                      </p>
                    </li>
                    <li>
                      <p class="mt-4 leading-relaxed text-[#686868]">
                        Maintain consistent on-brand creative within a
                        fast-paced and collaborative environment.
                      </p>
                    </li>
                    <li>
                      <p class="mt-4 leading-relaxed text-[#686868]">
                        Demonstrate a high level of mastery with CSS and the
                        ability to execute on complex designs, as well as a
                        focused understanding of JavaScript.
                      </p>
                    </li>
                  </ul>
                  <div className="mt-5">
                    <h2 className="text-base font-bold text-[#3c3c3c] my-2">
                      Desired Profile: Please refer to the Job description above
                    </h2>

                    <div class="overflow-x-auto">
                      <table class="min-w-full divide-y-2 divide-gray-200  text-sm">
                        <tbody class="divide-y divide-gray-200">
                          <tr>
                            <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                              Experience
                            </td>
                            <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                              1 Year - 2 Years
                            </td>
                          </tr>

                          <tr>
                            <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                              Industry Type
                            </td>
                            <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                              Software Services
                            </td>
                          </tr>

                          <tr>
                            <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                              Role:
                            </td>
                            <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                              Graphic Designer
                            </td>
                          </tr>
                          <tr>
                            <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                              Email-id:
                            </td>
                            <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                              hr@ajivainfotech.com
                            </td>
                          </tr>
                          <tr>
                            <td class="whitespace-nowrap px-4 py-2 font-normal text-[#686868] text-base">
                              Telephone
                            </td>
                            <td class="whitespace-nowrap px-4 py-2 text-[#686868] text-base">
                              +91-0000000000
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </details>
            </div>
            <div className="lg:w-1/2 lg:px-10 px-2 py-4">
              <form action="" name="contactform" id="contactid" class="">
                <input
                  type="text"
                  name="fullname"
                  class="w-full border-gray-200 p-4 pe-12 border my-2 bg-[#f2f2f2]"
                  placeholder="Name:"
                />
                <input
                  type="number"
                  name="phone"
                  class="w-full  border-gray-200 p-4 pe-12 border my-2  bg-[#f2f2f2]"
                  placeholder="Phone No:"
                />
                <input
                  type="email"
                  name="email"
                  class="w-full border-gray-200 p-4 pe-12 border my-2  bg-[#f2f2f2]"
                  placeholder="Email ID:"
                />
                <div className="genderdiv mx-1">
                  <input
                    type="radio"
                    name="gender"
                    id="male"
                    value="male"
                    checked
                    style={{ height: "20px", width: "20px" }}
                  />
                  <label for="male" className="text-base mx-2 font-semibold">
                    Male
                  </label>
                  <input
                    type="radio"
                    name="gender"
                    id="female"
                    value="female"
                    style={{ height: "20px", width: "20px" }}
                  ></input>
                  <label for="female" className="text-base mx-2 font-semibold">
                    Female
                  </label>
                </div>
                <textarea
                  className="w-full border-gray-200 p-4 pe-12 border my-2 h-[180px]  bg-[#f2f2f2]"
                  id=""
                  name="Address"
                  placeholder="Address:"
                ></textarea>
                <div className="fileselectdiv py-4 lg:py-0">
                  <label
                    className="text-base  font-semibold text-[#2a2a2a]"
                    style={{ display: "block", padding: "5px 0 5px 0px" }}
                  >
                    Upload your CV
                  </label>
                  <input
                    type="file"
                    accept=".pdf"
                    className="text-[#878787] font-medium"
                    onChange={handleFileChange}
                  />
                </div>
                <button
                  className="inline-block rounded border border-red-600 bg-red-600 lg:py-2 py-2 px-4 mb-4 lg:my-4 text-xl
                  font-semibold text-white hover:bg-transparent hover:text-red-600 
                  focus:outline-none focus:ring active:text-black-500 w-full lg:w-56
 "
                  type="button"
                >
                  SUBMIT
                </button>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default Career;

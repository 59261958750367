import React, { useState } from "react";
import Thankupagaeimage from "../../img/wpmantinance/Thankupagaeimage.webp";
import Popup from "../popup/popupwordpress.js";
const OnboardingSection = () => {
  const [currentStep, setCurrentStep] = useState(2); // Default to Step 2

  // Mapping step numbers to YouTube embed links
  const stepVideos = {
    1: "https://www.youtube.com/embed/0EheX7Nhn0g", // Replace with step-specific videos
    2: "https://www.youtube.com/embed/0EheX7Nhn0g",
    3: "https://www.youtube.com/embed/0EheX7Nhn0g",
    4: "https://www.youtube.com/embed/0EheX7Nhn0g",
  };

  const handleStepChange = (step) => {
    setCurrentStep(step);
  };
  const [open, setOpen] = useState(true);

  return (
    <>
      <div className="main-wordpess-section-thankyou min-h-screen from-black via-gray-900 to-black text-white px-6 py-12 flex flex-col items-center">
        <div className="container thankyoupage">
          {/* Header Section */}
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4 text-white">
              <span className="checkoutmembership">Onboarding</span> Information
            </h1>
          </div>

          {/* Steps Section */}
          <div className="flex items-center space-x-4 ">
            <div className="bg-red-500 text-white px-6 py-2 rounded-t-lg font-semibold shadow-lg">
              Step 2: Submit Access Details
            </div>
            <div className=" text-gray-400 px-6 py-2 rounded-t-lg font-semibold shadow-lg">
              Step 3: Setup In Progress
            </div>
            <div className=" text-gray-400 px-6 py-2 rounded-t-lgfont-semibold shadow-lg">
              Step 4: Maintenance Begins
            </div>
          </div>

          {/* Form Section */}

          <div className="rounded-xl shadow-xl  max-w-4/5 m-auto w-full thankyoupagemainform">
            <div className="grid md:grid-cols-2 ">
              <div className="">
                <img src={Thankupagaeimage} />
              </div>
              {/* WordPress Admin Access */}
              <div className="wpforms p-10">
                <h2 className="text-2xl  font-bold text-white mb-6">
                  WordPress Admin Access
                </h2>
                <form className="space-y-6">
                  <div>
                    <label className="block text-gray-400 mb-2">
                      Admin URL
                    </label>
                    <input
                      type="text"
                      placeholder="E.g., https://example.com/wp-admin"
                      className="w-full px-4 py-3 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-red-500"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-400 mb-2">
                      User Name
                    </label>
                    <input
                      type="text"
                      placeholder="Your Name"
                      className="w-full px-4 py-3 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-red-500"
                    />
                  </div>

                  <div>
                    <label className="block text-gray-400 mb-2">Password</label>
                    <input
                      type="password"
                      placeholder="********"
                      className="w-full px-4 py-3 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-red-500"
                    />
                  </div>
                </form>
                {/* Hosting Access */}
                <div>
                  <h2 className="text-2xl font-bold text-white mb-6">
                    Hosting Access
                  </h2>
                  <form className="space-y-6">
                    <div>
                      <label className="block text-gray-400 mb-2">
                        Hosting Provider
                      </label>
                      <input
                        type="text"
                        placeholder="E.g., Bluehost, SiteGround"
                        className="w-full px-4 py-3 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-red-500"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-400 mb-2">
                        CPanel/FTP URL
                      </label>
                      <input
                        type="url"
                        placeholder="https://example.com/cpanel"
                        className="w-full px-4 py-3 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-red-500"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-400 mb-2">
                        User Name
                      </label>
                      <input
                        type="text"
                        placeholder="User Name"
                        className="w-full px-4 py-3 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-red-500"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-400 mb-2">
                        Password
                      </label>
                      <input
                        type="password"
                        placeholder="********"
                        className="w-full px-4 py-3 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-red-500"
                      />
                    </div>
                  </form>
                  {/* Submit Button */}
                  <div className="mt-8 text-center">
                    <button className="bg-red-500 px-8 py-3 rounded-full text-white font-semibold hover:bg-red-600 transition-all shadow-lg">
                      Submit Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup show={open} onClose={() => setOpen(false)}>
        <div className="mainwp-popup">
          <div className="max-w-5xl mx-auto p-6 text-white">
            <h1 className="text-3xl font-bold text-center mb-6">
              Onboarding Guide
            </h1>
            <div className="flex justify-center space-x-4 mb-6">
              {Object.keys(stepVideos).map((step) => (
                <button
                  key={step}
                  onClick={() => handleStepChange(Number(step))}
                  className={`px-4 py-2 rounded-lg font-medium ${
                    currentStep === Number(step)
                      ? "bg-green-500"
                      : "bg-red-500 hover:bg-red-600"
                  }`}
                >
                  Step {step}: {getStepTitle(Number(step))}
                </button>
              ))}
            </div>
            <div className="aspect-w-16 aspect-h-9">
              <iframe
                className="w-full rounded-lg shadow-lg"
                style={{ height: "400px" }}
                src={`${stepVideos[currentStep]}?autoplay=1`}
                title={`Step ${currentStep}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            {/* Navigation Buttons */}
            <div className="flex justify-between mt-6">
              <button
                className={`px-6 py-2 rounded-lg text-white font-semibold ${
                  currentStep > 1
                    ? "bg-blue-500 hover:bg-blue-600"
                    : "bg-gray-500 cursor-not-allowed"
                }`}
                onClick={() => handleStepChange(currentStep - 1)}
                disabled={currentStep === 1}
              >
                Previous
              </button>
              <button
                className={`px-6 py-2 rounded-lg text-white font-semibold ${
                  currentStep < Object.keys(stepVideos).length
                    ? "bg-blue-500 hover:bg-blue-600"
                    : "bg-gray-500 cursor-not-allowed"
                }`}
                onClick={() => handleStepChange(currentStep + 1)}
                disabled={currentStep === Object.keys(stepVideos).length}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};
const getStepTitle = (step) => {
  switch (step) {
    case 1:
      return "Overview";
    case 2:
      return "Access Details";
    case 3:
      return "Setup";
    case 4:
      return "Maintenance";
    default:
      return "";
  }
};
export default OnboardingSection;

import React, { useState } from "react";
import Aservices from "./Aservices";
import Helmet from "react-helmet";
import logo from "../../img/logo192.png";
import Ecommerce_image_ecc from "../../aservecse_img/About_Ajiva_Eccomerce.png";
import Ecommerce_image from "../../aservecse_img/ecom_pg_lfimg_1.png";
import Ecommerce_image_2 from "../../aservecse_img/ecom_pg_lfimg_2.png";
import Ecommerce_image_3 from "../../aservecse_img/Eccomerce_3.png";
import Ecommerce_image_4 from "../../aservecse_img/Eccomerce_4.png";
import Eccomerce_SCO from "../../aservecse_img/Eccomerce_SCO.png";
import new_design from "../../aservecse_img/New_design.png";
import FormSection from "../../components/Website/servicepageheadersection.js";
import Bgimageecinnerce from "../../img/banner/bgimageecinnerce.png";

import Popup from "../popup/dynamicpopup.js";
import Contactpopups from "./contactpopup.js";
function Mobile_App_Development() {
  const [showPopup, setShowPopup] = useState(false);
  const cards = [
    {
      title: "Mobile App Development Services",
      description:
        "Welcome to our premier Mobile App Development Services, where innovation meets functionality. We pride ourselves on being among the best mobile app development services, delivering top-notch solutions that cater to your unique business needs.",
      image: Ecommerce_image,
    },
    {
      title: "Custom Mobile App Development",
      description:
        "We understand that every business is unique. Our custom mobile app development services ensure that your app is tailored to meet your specific requirements, enhancing user experience and engagement.",
      image: Ecommerce_image_2,
    },
    {
      title: "Affordable Mobile App Development",
      description:
        "Quality doesn't have to come at a high price. Our affordable mobile app development solutions offer competitive pricing without compromising on excellence.",
      image: Ecommerce_image_3,
    },
    {
      title: "Professional Mobile App Development",
      description:
        "With a team of seasoned developers, designers, and strategists, we deliver professional mobile app development services that align with your business goals and industry standards.",
      image: Ecommerce_image_4,
    },
    {
      title: "Platforms and Technologies",
      description:
        "We At Ajiva Infotech, develop mobile applications for various platforms and technologies, ensuring broad compatibility and performance.",
      image: Ecommerce_image,
    },
    {
      title: "iOS App Development",
      description:
        "Our experts create seamless, high-performance iOS apps using Swift and Objective-C, tailored for iPhone and iPad users.",
      image: Ecommerce_image_3,
    },
    {
      title: "Android App Development",
      description:
        "Leveraging Java and Kotlin, we develop robust and scalable Android applications that cater to a diverse audience.",
      image: Eccomerce_SCO,
    },
    {
      title: "Cross-Platform App Development",
      description:
        "Using frameworks like React Native, Flutter, and Xamarin, we build apps that work flawlessly across both iOS and Android, ensuring a wider reach and reduced development time.",
      image: Ecommerce_image_ecc,
    },
    {
      title: "Web App Development",
      description:
        "Our team excels in developing progressive web apps (PWAs) that deliver a native app-like experience through web technologies such as HTML5, CSS3, and JavaScript.",
      image: Ecommerce_image_ecc,
    },
    {
      title: "Our App Development Process",
      description:
        "Our mobile app development process is designed to deliver efficient, high-quality results.",
      image: Ecommerce_image_ecc,
    },
    {
      title: "Discovery & Planning",
      description:
        "We start with understanding your vision, goals, and requirements. Our team conducts thorough research and planning to lay a strong foundation for the project.",
      image: Ecommerce_image_ecc,
    },
    {
      title: "UI/UX Design",
      description:
        "Our designers craft intuitive and visually appealing interfaces, ensuring a user-friendly experience. Wireframes and prototypes are created for client approval before development begins.",
      image: new_design,
    },
    {
      title: "Development",
      description:
        "Our developers bring your app to life using the latest technologies and best practices. We follow agile methodologies to ensure iterative progress and transparency.",
      image: Ecommerce_image_ecc,
    },
    {
      title: "Testing",
      description:
        "Rigorous testing is conducted to identify and resolve any issues. We perform functional, performance, and security testing to ensure the app is reliable and secure.",
      image: new_design,
    },
    {
      title: "Deployment",
      description:
        "Our deployment services ensure your app is launched seamlessly across all platforms. We handle every aspect of deployment, ensuring that your app performs optimally for your users.",
      image: Eccomerce_SCO,
    },
    {
      title: "Maintenance & Support",
      description:
        "We offer ongoing maintenance and support services to ensure your mobile app stays up-to-date with the latest features, improvements, and bug fixes, providing your users with the best experience possible.",
      image: Eccomerce_SCO,
    },
    {
      title: "Partner with Us",
      description:
        "Looking to collaborate with us on an exciting project? Partner with our team for top-quality app development services that will help you achieve your business goals.",
      image: new_design,
    },
  ];
  const services = [
    "Mobile App Development Services",
    "Affordable Mobile App Development",
    "Professional Mobile App Development",
    "Platforms and Technologies",
    "iOS App Development & Android App Development",
    "Cross-Platform App Development",
    "Web App Development",
  ];

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="title"
          content=" Best Mobile App Development Services | Custom & Affordable App Solutions  "
        />

        <title>
          Best Mobile App Development Services | Custom & Affordable App
          Solutions
        </title>
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" Best mobile app development services, top mobile app development companies, custom mobile app development, affordable mobile app development, professional mobile app development"
        />
        <meta
          name="description"
          content="Discover the best mobile app development services offering custom, affordable, and professional solutions for iOS, Android, and cross-platform apps. Partner with one of the top mobile app development companies to turn your vision into reality. Contact us today for a seamless app development process.
  "
        />
        <link rel="apple-touch-icon" href={logo} />
      </Helmet>
      <div className="cocontainer m-auton">
        <section>
          <div className=" container m-auto lg:flex justify-between Ecommerce-bg-image items-center">
            <div className=" building-style">
              <h1 style={{ color: "#EE1021" }}>Building</h1>
              <h2>Beautiful Mobile App Development that Drive</h2>
              <h2>Results and Inspire Engagement</h2>
              <p>
                From concept to launch, we'll handle your Mobile App Development
                with precision and expertise
              </p>
              <div className="flex company-details gap-8 mt-12">
                <div className="flex items-center gap-2">
                  <span>
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30.1625 7.77409C28.7107 6.30809 26.9816 5.1457 25.0761 4.35466C23.1705 3.56362 21.1266 3.15975 19.0633 3.16659C10.4183 3.16659 3.37252 10.2124 3.37252 18.8574C3.37252 21.6283 4.10085 24.3199 5.46252 26.6949L3.24585 34.8333L11.5583 32.6483C13.8542 33.8991 16.435 34.5641 19.0633 34.5641C27.7083 34.5641 34.7542 27.5183 34.7542 18.8733C34.7542 14.6774 33.1234 10.7349 30.1625 7.77409ZM19.0633 31.9041C16.72 31.9041 14.4242 31.2708 12.4134 30.0833L11.9383 29.7983L6.99835 31.0966L8.31252 26.2833L7.99585 25.7924C6.69363 23.7136 6.00231 21.3104 6.00085 18.8574C6.00085 11.6691 11.8592 5.81076 19.0475 5.81076C22.5308 5.81076 25.8083 7.17242 28.2625 9.64242C29.4779 10.8519 30.4411 12.2906 31.0961 13.8752C31.7511 15.4598 32.085 17.1586 32.0784 18.8733C32.11 26.0616 26.2517 31.9041 19.0633 31.9041ZM26.22 22.1508C25.8242 21.9608 23.8925 21.0108 23.5442 20.8683C23.18 20.7416 22.9267 20.6783 22.6575 21.0583C22.3883 21.4541 21.6442 22.3408 21.4225 22.5941C21.2008 22.8633 20.9634 22.8949 20.5675 22.6891C20.1717 22.4991 18.905 22.0716 17.4167 20.7416C16.245 19.6966 15.4692 18.4141 15.2317 18.0183C15.01 17.6224 15.2 17.4166 15.4058 17.2108C15.58 17.0366 15.8017 16.7516 15.9917 16.5299C16.1817 16.3083 16.2608 16.1341 16.3875 15.8808C16.5142 15.6116 16.4508 15.3899 16.3558 15.1999C16.2608 15.0099 15.4692 13.0783 15.1525 12.2866C14.8358 11.5266 14.5034 11.6216 14.2659 11.6058H13.5058C13.2367 11.6058 12.825 11.7008 12.4608 12.0966C12.1125 12.4924 11.0992 13.4424 11.0992 15.3741C11.0992 17.3058 12.5084 19.1741 12.6983 19.4274C12.8884 19.6966 15.4692 23.6549 19.3958 25.3491C20.33 25.7608 21.0583 25.9983 21.6283 26.1724C22.5625 26.4733 23.4175 26.4258 24.0984 26.3308C24.8584 26.2199 26.4258 25.3808 26.7425 24.4624C27.075 23.5441 27.075 22.7683 26.9642 22.5941C26.8534 22.4199 26.6158 22.3408 26.22 22.1508Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <h1>+91-86999-81982</h1>
                </div>

                <div className="flex items-center gap-2">
                  <span>
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.33334 31.6666C5.46251 31.6666 4.71728 31.3568 4.09767 30.7372C3.47806 30.1176 3.16773 29.3718 3.16667 28.4999V9.49992C3.16667 8.62909 3.47701 7.88386 4.09767 7.26425C4.71834 6.64464 5.46356 6.33431 6.33334 6.33325H31.6667C32.5375 6.33325 33.2833 6.64358 33.9039 7.26425C34.5246 7.88492 34.8344 8.63014 34.8333 9.49992V28.4999C34.8333 29.3708 34.5235 30.1165 33.9039 30.7372C33.2843 31.3578 32.5386 31.6676 31.6667 31.6666H6.33334ZM19 20.5833L6.33334 12.6666V28.4999H31.6667V12.6666L19 20.5833ZM19 17.4166L31.6667 9.49992H6.33334L19 17.4166ZM6.33334 12.6666V9.49992V28.4999V12.6666Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <h1> info@ajivainfotech.com</h1>
                </div>

                <div className="flex items-center gap-2">
                  <span class="phone_content p-">
                    <i class="fa-solid fa-phone-volume text-white text-xl"></i>
                  </span>
                  <h1>0172-4084189</h1>
                </div>
              </div>
            </div>
            <div className="building-form-style">
              <div className="Consultation-style">
                {" "}
                <h1 className="mt-2">Get Consultation now!</h1>
                <h2 className="mt-1">
                  Let’s Discuss to Build Something Great Together!
                </h2>
                <FormSection />
              </div>
            </div>
          </div>
          <section className="bg-white py-12 px-4 container m-auto responcivemedia">
            <div className=" flex flex-col md:flex-row  gap-14">
              <div className="relative w-full md:w-1/2">
                <img
                  src={Bgimageecinnerce}
                  alt="Team Meeting"
                  className=" w-full h-auto"
                />
              </div>

              {/* Right Column: Text Content */}
              <div className="w-full md:w-1/2 commerce-website">
                <h2 className="font-bold  mb-6 pr-12  mr-12">
                  Transform Your Online Business With Our Mobile App Development
                  Services
                </h2>
                <p className=" mb-6 leading-relaxed  ">
                  Are you looking to take your online business to the next
                  level? At AIJIVA INFOTECH, our expert team specializes in
                  e-commerce website design and development, providing custom
                  solutions that cater to your unique business needs. With a
                  focus on affordability and quality, we offer professional
                  e-commerce development services to help you succeed in the
                  digital marketplace.
                </p>
                <ul className="space-y-3">
                  {services.map((service, index) => (
                    <li key={index} className="flex items-center">
                      <span className="text-red-600 mr-2">➤</span>
                      {service}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>
          <section className="container m-auto">
            <div className="ecommerce-heading py-12 text-center">
              <h1 className="">Mobile App Development</h1>
              <h2 className="">Mobile App Development Services</h2>
            </div>
          </section>
          <section className="bg-gray-100 py-12 px-4">
            <div className="container m-auto">
              {" "}
              <div className=" mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
                {cards.map((card) => (
                  <div
                    key={card.id}
                    className="bg-white rounded-lg shadow-lg overflow-hidden text-center p-6"
                  >
                    <img
                      src={card.image}
                      alt={card.title}
                      className="w-full  object-cover mb-4 rounded-md"
                    />
                    <h3 className=" font-bold text-gray-800 mb-4">
                      {card.title}
                    </h3>
                    <p className="text-gray-600 mb-6 text-sm leading-relaxed">
                      {card.description}
                    </p>
                    <div className="details-card">
                      {/* Button to toggle popup */}
                      <button
                        className="explore"
                        onClick={() => setShowPopup(true)} // Open the popup
                      >
                        <span className="icon-right after"></span>
                      </button>

                      {/* Popup */}
                      <Popup
                        show={showPopup}
                        onClose={() => setShowPopup(false)}
                      >
                        <Contactpopups /> {/* Render your popup content here */}
                      </Popup>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </section>
        {/* <section>
          <div className="flex justify-center">
            <img src={contact_bg} alt="contact_bg" />
          </div>
          <div className="container m-auto ">
            <div className="">
              <div className="opp">
                <div className="bang">
                  <h1 className="text-red-700	 ">Mobile App Development</h1>
                </div>
                <div className="Api_int">
                  <NavLink to="/" className="text-base text-red-600	pr-2">
                    Home
                  </NavLink>
                  <span className="text-sm">/</span>
                  <h1 className="pl-2 text-[18px] lg:text-2xl font-bold text-[#333333]">
                    Mobile App Development
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <div className="ecomerece_border_frist_main">
          <div className="container m-auto">
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image ">
                  <img src={Ecommerce_image} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Mobile App Development Services
                </h1>
                <p className="">
                  Welcome to our premier Mobile App Development Services, where
                  innovation meets functionality. We pride ourselves on being
                  among the{" "}
                  <span className="font-bold">
                    best mobile app development services
                  </span>
                  , delivering top-notch solutions that cater to your unique
                  business needs. As one of the
                  <span className="font-bold">
                    {" "}
                    top mobile app development companies
                  </span>
                  , we specialize in crafting custom, professional, and
                  affordable mobile applications for a variety of platforms and
                  technologies.
                </p>
              </div>
            </div>
            <div className="Integration-Needs">
              <h1>Why Choose Our Mobile App Development Services?</h1>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Custom Mobile App Development
                </h1>
                <p className="">
                  We understand that every business is unique. Our custom mobile
                  app development services ensure that your app is tailored to
                  meet your specific requirements, enhancing user experience and
                  engagement.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_2} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Affordable Mobile App Development:
                </h1>
                <p className="">
                  Quality doesn't have to come at a high price. Our affordable
                  mobile app development solutions offer competitive pricing
                  without compromising on excellence.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change  ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Professional Mobile App Development
                </h1>
                <p className="">
                  With a team of seasoned developers, designers, and
                  strategists, we deliver professional mobile app development
                  services that align with your business goals and industry
                  standards.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_4} alt="Ecommerce_image" />
                </div>
              </div>
            </div>

            <div className="Integration-Needs">
              <h1>Platforms and Technologies</h1>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] reveal ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]"></h1>
                <p className="">
                  We At <span>Ajiva Infotech</span>, develop mobile applications
                  for various platforms and technologies, ensuring broad
                  compatibility and performance:
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  iOS App Development:
                </h1>
                <p className="">
                  Our experts create seamless, high-performance iOS apps using
                  Swift and Objective-C, tailored for iPhone and iPad users.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2  ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Eccomerce_SCO} />
                </div>
              </div>
              <div className="lg:w-1/2   Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Android App Development
                </h1>
                <p className="">
                  Leveraging Java and Kotlin, we develop robust and scalable
                  Android applications that cater to a diverse audience.
                </p>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Cross-Platform App Development
                </h1>
                <p className="">
                  Using frameworks like React Native, Flutter, and Xamarin, we
                  build apps that work flawlessly across both iOS and Android,
                  ensuring a wider reach and reduced development time.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Web App Development
                </h1>
                <p className="">
                  Our team excels in developing progressive web apps (PWAs) that
                  deliver a native app-like experience through web technologies
                  such as HTML5, CSS3, and JavaScript.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Our App Development Process
                </h1>
                <p className="">
                  Our mobile app development process is designed to deliver
                  efficient, high-quality results:
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Discovery & Planning
                </h1>
                <p className="">
                  We start with understanding your vision, goals, and
                  requirements. Our team conducts thorough research and planning
                  to lay a strong foundation for the project.
                </p>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  UI/UX Design
                </h1>
                <p className="">
                  Our designers craft intuitive and visually appealing
                  interfaces, ensuring a user-friendly experience. Wireframes
                  and prototypes are created for client approval before
                  development begins.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Development
                </h1>
                <p className="">
                  Our developers bring your app to life using the latest
                  technologies and best practices. We follow agile methodologies
                  to ensure iterative progress and transparency.
                </p>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Testing
                </h1>
                <p className="">
                  Rigorous testing is conducted to identify and resolve any
                  issues. We perform functional, performance, and security
                  testing to ensure the app is reliable and secure.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Deployment
                </h1>
                <p className="">
                  Once the app meets our quality standards, we assist with the
                  deployment process on the respective app stores. Our team
                  ensures a smooth launch and provides post-deployment support.
                </p>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Maintenance & Support
                </h1>
                <p className="">
                  We offer ongoing maintenance and support services to keep your
                  app updated and running smoothly. Our team is always ready to
                  implement updates and new features as needed.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer  bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Partner with Us
                </h1>
                <p className="">
                  As one of the{" "}
                  <span className="font-bold">
                    top mobile app development companies
                  </span>
                  , we are committed to turning your ideas into successful
                  mobile applications. Whether you need{" "}
                  <b className="font-bold">custom mobile app development</b> or{" "}
                  <b className="font-bold">
                    affordable mobile app development{" "}
                  </b>
                  , our professional team is here to deliver exceptional
                  results. Contact us today to discuss your project and discover
                  how we can help you achieve your mobile app goals.
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <Aservices />
      </div>
    </>
  );
}

export default Mobile_App_Development;

import React, { Suspense } from "react";
import Helmet from "react-helmet";
import logo from "../../img/logo192.png";
import Subscription from "../../components/Website/Subscription";

// Lazy loading non-critical sections
const Section1 = React.lazy(
  () => import("./WebsiteMaintenanceServices/WebsiteMaintenancesection.js")
);
const Section2 = React.lazy(
  () => import("./WebsiteMaintenanceServices/WebsiteMaintenancesection2.js")
);
const Section3 = React.lazy(
  () => import("./WebsiteMaintenanceServices/WebsiteMaintenancesection3.js")
);
const Section4 = React.lazy(
  () => import("./WebsiteMaintenanceServices/WebsiteMaintenancesection4.js")
);
const Section5 = React.lazy(
  () => import("./WebsiteMaintenanceServices/WebsiteMaintenancesection5.js")
);
const Section6 = React.lazy(
  () => import("./WebsiteMaintenanceServices/WebsiteMaintenancesection6.js")
);
const Section7 = React.lazy(
  () => import("./WebsiteMaintenanceServices/WebsiteMaintenancesection7.js")
);
const Section8 = React.lazy(
  () => import("./WebsiteMaintenanceServices/WebsiteMaintenancesection8.js")
);
const Section9 = React.lazy(
  () => import("./WebsiteMaintenanceServices/WebsiteMaintenancesection9.js")
);

const WordPressMaintenance = () => {
  return (
    <>
      {/* Helmet for SEO and Metadata */}
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href={logo} />
        <link rel="apple-touch-icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="title"
          content="WordPress Website Maintenance services: Secure, and Reliable"
        />
        <title>
          WordPress Website Maintenance services: Seamless, Secure, and Reliable
        </title>
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="WordPress Website Maintenance Services, wordpress maintenance services, wordpress website maintenance cost, WordPress security services."
        />
        <meta
          name="description"
          content="Keep your website fast, secure, and up-to-date with our WordPress Website Maintenance Services. Reliable support at budget-friendly pricing!"
        />
      </Helmet>

      <div className="ajv-maintenance-page">
        <Suspense fallback={<div></div>}>
          <Section1 />
        </Suspense>

        <div className="overflow-hidden relative ">
          <div className="">
            <Suspense fallback={<div></div>}>
              <Section2 />
            </Suspense>
          </div>

          <div className="bgbackcolor">
            <Suspense fallback={<div></div>}>
              <Section3 />
            </Suspense>{" "}
            <Suspense fallback={<div></div>}>
              <Section4 />
            </Suspense>
          </div>
        </div>

        <Subscription />

        <Suspense fallback={<div></div>}>
          <Section5 />
        </Suspense>
        <Suspense fallback={<div></div>}>
          <Section6 />
        </Suspense>
        <Suspense fallback={<div></div>}>
          <Section7 />
        </Suspense>
        <Suspense fallback={<div></div>}>
          <Section8 />
        </Suspense>
        <Suspense fallback={<div>Loading Section...</div>}>
          <Section9 />
        </Suspense>
      </div>
    </>
  );
};

export default WordPressMaintenance;

// Import React and useState
import React, { useState } from "react";
import Helmet from "react-helmet";

import logo from "../../../img/logo192.png";

const ImageConverter = () => {
  const [pngFile, setPngFile] = useState(null);
  const [pngUrl, setPngUrl] = useState(null);
  const [jpgUrl, setJpgUrl] = useState(null);

  // Function to handle file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "image/png") {
      setPngFile(file);
      setPngUrl(URL.createObjectURL(file));
      setJpgUrl(null); // Reset the JPEG URL when a new PNG is selected
    } else {
      setPngFile(null);
      setPngUrl(null);
      alert("Please select a valid PNG image file.");
    }
  };

  // Function to convert PNG to JPEG and initiate download
  const convertAndDownload = () => {
    if (!pngFile) {
      alert("Please select a PNG image file first.");
      return;
    }

    const img = new Image();
    img.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);

      canvas.toBlob((blob) => {
        const jpgFile = new File(
          [blob],
          `${pngFile.name.replace(".png", "")}.jpg`,
          { type: "image/jpeg" }
        );
        const url = URL.createObjectURL(jpgFile);
        setJpgUrl(url);
      }, "image/jpeg");
    };
    img.src = URL.createObjectURL(pngFile);
  };

  return (
    <>
      <Helmet>{/* Your Helmet meta tags */}</Helmet>
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 ">
        <div className="border p-10 rounded w-[50%]">
          <h2 className="text-2xl text-center font-bold mb-4">
            Image Converter: PNG to JPEG
          </h2>
          <div className="flex justify-between mb-4">
            <input
              type="file"
              accept=".png"
              onChange={handleFileChange}
              className="px-4 py-2 border rounded-lg text-gray-700 w-[70%] focus:outline-none focus:ring-2 focus:ring-blue-500"
            />

            <button
              onClick={convertAndDownload}
              className="px-6 py-2 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Convert PNG to JPEG
            </button>
          </div>

          {pngUrl && (
            <div className="mb-4 text-center">
              <h3 className="text-lg font-semibold mb-2">
                Selected PNG Image:
              </h3>
              <img
                src={pngUrl}
                alt="Selected PNG"
                className="w-full max-w-xs mx-auto"
              />
            </div>
          )}

          {jpgUrl && (
            <div className="text-center">
              <h3 className="text-lg font-semibold mb-2">
                Converted JPEG Image:
              </h3>
              <img
                src={jpgUrl}
                alt="Converted JPEG"
                className="w-full max-w-xs mx-auto"
              />
              <button>
                <a
                  href={jpgUrl}
                  download="converted_image.jpg"
                  className="block mt-2 px-4 py-2 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Download Converted Image
                </a>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ImageConverter;

import React, { useState } from "react";
import api from "../../Api";
import logo from "../../image/logo-change.png";
const Success = ({ message }) => (
  <div className=" w-4/5 flex flex-col items-center justify-center  m-auto">
    <div className="text-center">
      <h1 className="md:text-2xl sm:text-lg font-semibold text-blue-900">
        Email Sent Successfully
      </h1>
      <p className="mt-2 text-gray-700">
        Reset password link sent to your email
      </p>
      <div className="mt-4 flex justify-center">
        <svg
          width="132"
          height="132"
          viewBox="0 0 132 132"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.9997 132C77.9228 132.001 89.6235 128.772 99.8586 122.657C110.094 116.541 118.481 107.767 124.129 97.2665C129.778 86.7662 132.476 74.932 131.938 63.0211C131.4 51.1101 127.645 39.5675 121.073 29.6193L68.603 87.9193C66.1522 90.6431 62.7706 92.3507 59.1238 92.7063C55.477 93.0619 51.8292 92.0396 48.8984 89.8407L24.933 71.8667C23.3771 70.6997 22.3485 68.9625 22.0734 67.0371C21.7984 65.1117 22.2994 63.1559 23.4664 61.6C24.6333 60.0441 26.3706 59.0154 28.296 58.7404C30.2213 58.4653 32.1771 58.9664 33.733 60.1333L57.6984 78.1073L111.569 18.26C103.765 10.8101 94.2534 5.38707 83.8674 2.46563C73.4814 -0.455814 62.5375 -0.786544 51.994 1.5024C41.4505 3.79134 31.6289 8.63015 23.3892 15.5952C15.1494 22.5602 8.74282 31.439 4.73028 41.4542C0.717743 51.4694 -0.778357 62.3155 0.372976 73.043C1.52431 83.7705 5.28796 94.0522 11.3344 102.988C17.3808 111.923 25.5257 119.24 35.0558 124.298C44.5859 129.356 55.2106 132.001 65.9997 132Z"
            fill="#4B8700"
          />
        </svg>
      </div>
    </div>
  </div>
);
const ForgotPassword = ({ redirect_url }) => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  //console.log(redirect_url);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userData = { email };
      const response = await api.forgotPassword(userData);
      //console.log(response);
      if (response.status == 200) {
        if (redirect_url != undefined) {
          localStorage.setItem("redirect_url", redirect_url);
        }
        setLoading(false);
        setSuccess(true);
        setSuccessMessage(response.msg);
      } else {
        onclose("");
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="login_inform login_inform-page h-screen">
        <div className="flex justify-center items-center login_inform-inner py-28 ">
          <div className="forgotpass-form  form_upto m-8  xl:w-1/3 lg:w-1/2 md:w-10/12 sm:w-2/3">
            <div className=" rounded px-6 pt-4 pb-6 mb-4">
              {success ? (
                <Success message={successMessage} />
              ) : (
                <div className="mb-1 text-center">
                  <div className="text-center HirePros-login-heading py-5 ">
                    <h4>
                      {" "}
                      Forgot your password? No problem. Just let us know your
                      email address and we will email you a password reset link
                      that will allow you to choose a new one.
                    </h4>
                  </div>
                  <form onSubmit={handleSubmit} className="animated-form">
                    <div className="mb-4 form-group">
                      <input
                        type="email"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                        placeholder=""
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label htmlFor="email" className="Email-label">
                        Email
                      </label>
                      {error && <p className="text-red-500">{error}</p>}
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="login-form-btn bg-[#003366] text-white py-2 px-4 rounded w-full focus:outline-none focus:shadow-outline"
                        disabled={loading}
                      >
                        {loading ? "Sending..." : "EMAIL PASSWORD RESET LINK"}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;

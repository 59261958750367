import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import api from "../../Api";
import { encryptData } from "../../helpers/encryption";
import CustomToastContainer from "../Common/CustomToastContainer";
const GoogleCallback = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const toastRef = useRef();
  useEffect(() => {
    // Extract the `code` and `state` from the URL parameters
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const state = searchParams.get("state");

    if (code) {
      // Send the code to your API
      handleGoogleAuth(code, state);
    } else {
      toastRef.current.addToast("Authorization code not found", "error");
    }
  }, [location]);

  const handleGoogleAuth = async (code, state) => {
    try {
      const userdata = { code, state };

      // Make the API call
      const response = await api.GoogleLogin(userdata);
      //console.log(response);

      // Check if response is successful
      if (response.status === 200) {
        const { accessToken, refreshToken, _id, role } = response.user;
        const encryptedUserData = encryptData(response.user);

        // Store data in localStorage
        localStorage.setItem("user", encryptedUserData);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        // Display success toast
        toastRef.current.addToast("Login Successfully", "success");
        // Handle redirect after successful login
        setTimeout(() => {
          const redirectdata = localStorage.getItem("redirect_url");

          if (redirectdata) {
            setLoading(false);
            window.location.href = redirectdata;
            localStorage.removeItem("redirect_url");
          } else {
            setLoading(false);
            window.location.href = "/dashboard"; // Use navigate for redirection
          }
        }, 2000);
      } else if (response.status === 400) {
        toastRef.current.addToast(response.data.msg, "error");
        setLoading(false);
      } else {
        toastRef.current.addToast("Authentication failed", "error");
      }
    } catch (error) {
      // Handle error in Google Authentication process
      console.error("Error during Google Authentication:", error);
      toastRef.current.addToast(error.response.data.msg, "error");
    }
  };

  return (
    <div>
      {loading ? "Processing Google Authentication..." : "Loading..."}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default GoogleCallback;

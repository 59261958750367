import React, { useState } from "react";
import "tailwindcss/tailwind.css";
import Helmet from "react-helmet";
import logo from "../../../img/logo192.png";
function ImageConverter() {
  const [image, setImage] = useState(null);
  const [convertedImage, setConvertedImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "image/jpeg") {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please upload a JPG file.");
    }
  };

  const convertToPNG = () => {
    if (image) {
      const canvas = document.createElement("canvas");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const pngUrl = canvas.toDataURL("image/png");
        setConvertedImage(pngUrl);
      };
      img.src = image;
    }
  };

  return (
    <>
    <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="" />
        <meta
          name="title"
          content="Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" coding validation tools, programming tools, code checker, code error finding tool, code beautify tool, code scanning tool"
        />
        <meta
          name="description"
          content=" Discover top coding validation tools to ensure error-free, high-quality code. Learn how tools like HTML Preview, Minyfiy to UnMinyfiy, Json Validator, Validate CSS, String Replace, Webhook Test can streamline your development workflow and enforce coding standards. "
        />
        {/* <link rel="apple-touch-icon" href={logo} /> */}
        <title>
          Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech
        </title>
      </Helmet>
      <div className="p-4 flex justify-center items-center min-h-screen bg-gray-100">
        <div className="bg-white shadow-lg rounded-lg p-6 max-w-md w-full">
          <h1 className="text-2xl font-bold text-center mb-4">
            JPG to PNG Converter
          </h1>
          <div className="flex flex-col items-center">
            <input
              type="file"
              accept="image/jpeg"
              onChange={handleImageUpload}
              className="mb-4 p-2 border rounded w-full"
            />
            {image && (
              <div className="mb-4 w-full">
                <h2 className="text-xl font-semibold mb-2">Original Image</h2>
                <img
                  src={image}
                  alt="Original JPG"
                  className="max-w-full h-auto border rounded mb-2"
                />
                <button
                  onClick={convertToPNG}
                  className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                >
                  Convert to PNG
                </button>
              </div>
            )}
            {convertedImage && (
              <div className="mt-4 w-full">
                <h2 className="text-xl font-semibold mb-2">Converted Image</h2>
                <img
                  src={convertedImage}
                  alt="Converted PNG"
                  className="max-w-full h-auto border rounded mb-2"
                />
                <a
                  href={convertedImage}
                  download="converted.png"
                  className="w-full px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 text-center"
                >
                  Download PNG
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ImageConverter;

import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "./Footer.js";
import "../../components/Layout/menubaar.css";
import Header from "./Header.js";
import Dasboard from "../Candidates/Dasboard.js";
import { Candidates } from "./dashboardmenus.js";
import { PopupProvider } from "../Common/PopupContext.js";

function AdminLayout() {
  const getCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ");

    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null; // Return null if the cookie is not found
  };

  const useLocalStorage = (key) => {
    const [storedValue, setStoredValue] = useState(() => {
      const item = localStorage.getItem(key);
      if (item) return item;

      // If not found in localStorage, try to retrieve it from cookies
      const cookieValue = getCookie(key);
      if (cookieValue) {
        localStorage.setItem(key, cookieValue); // Set to localStorage
        return cookieValue;
      }

      return null;
    });

    return storedValue;
  };

  const user = useLocalStorage("accessToken");
  useEffect(() => {}, [user]);

  return (
    <PopupProvider>
      <div className="">
        <Header />

        <div className="container m-auto hrp-min-h-screen">
          <div className="w-full hrp-min-width">
            {user ? <Candidates /> : ""}
            <section className="mt-5">
              <div className="bg-white container hrp-candidate-page JobCategories-heading-admin m-auto">
                <Routes>
                  {/* Nested Routes */}
                  <Route path="/" element={<Dasboard />} />
                  {/* Add other routes here as needed */}
                </Routes>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    </PopupProvider>
  );
}

export default AdminLayout;

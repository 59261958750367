/* global Razorpay */
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import checkoutbgImage from "../../img/wpmantinance/checkoutbg.webp";
import Thankupage from "./ThankYouWordPress";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CustomToastContainer from "../Common/CustomToastContainer";
import WpmantinanceLogo from "./../../img/wpmantinance/wp-mantinance-logo.webp";
const CheckoutPage = () => {
  const location = useLocation();
  const { plan } = location.state || {};
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  });
  const toastRef = useRef();
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [expand, setExpand] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const logoRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [isLoginMode, setIsLoginMode] = useState(false);
  const [countryCode, setCountryCode] = useState("us");
  const [isThankYouPage, setIsThankYouPage] = useState(false);

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        } else {
          entry.target.classList.remove("visible");
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.5,
    });

    if (logoRef.current) observer.observe(logoRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        console.log("Is logo in viewport?", entry.isIntersecting); // Debugging visibility
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (logoRef.current) {
      observer.observe(logoRef.current);
    }

    return () => {
      if (logoRef.current) {
        observer.unobserve(logoRef.current);
      }
    };
  }, []);
  // State to control Thank You Page rendering
  const validateField = (name, value) => {
    let errorMessage = "";

    switch (name) {
      case "phone":
        // Remove non-digit characters for length validation
        const numericValue = value.replace(/[^0-9]/g, "");
        if (
          !/^\+?[1-9]\d{0,3}[-\s]?(\(?\d{1,4}\)?[-\s]?){1,3}\d{1,4}$/.test(
            value
          )
        ) {
          errorMessage =
            "Enter a valid phone number with country code (e.g., +1234567890).";
        } else if (numericValue.length < 7 || numericValue.length > 13) {
          errorMessage = "Phone number must have 7 to 13 digits.";
        }
        break;

      default:
        break;
    }

    return errorMessage;
  };

  const HandleClick = () => {
    navigate("/subscription");
  };
  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };
  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("/api/login", {
        email: loginData.email,
        password: loginData.password,
      });

      if (response.status === 200) {
        // Handle successful login
        alert("Login successful. Proceeding to checkout.");
        handleSubmit(e); // Start the checkout process
      } else {
        alert("Invalid credentials. Please try again.");
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("An error occurred during login. Please try again.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form data
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validate the current field
    const errorMessage = validateField(name, value);

    // Update errors
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(async (position) => {
            const { latitude, longitude } = position.coords;
            const response = await fetch(
              `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
            );
            const data = await response.json();
            if (data && data.countryCode) {
              setCountryCode(data.countryCode.toLowerCase()); // Use lowercase for PhoneInput
            }
          });
        }
      } catch (error) {
        console.error("Error fetching geolocation:", error);
      }
    };

    fetchCountryCode();
  }, []);
  useEffect(() => {
    const loadRazorpayScript = () => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      script.onload = () => {
        console.log("Razorpay SDK loaded successfully");
        window.isRazorpayLoaded = true; // Set a flag to indicate script is loaded
      };
      script.onerror = () => {
        console.error("Failed to load Razorpay SDK");
      };
      document.body.appendChild(script);
    };

    loadRazorpayScript();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!window.isRazorpayLoaded || typeof Razorpay === "undefined") {
      alert("Razorpay SDK is not loaded. Please wait and try again.");
      return;
    }

    try {
      const response = await axios.post("/api/create-order", {
        firstname: formData.firstname,
        lastname: formData.lastname,
        email: formData.email,
        phone: formData.phone,
        planId: plan.plan_id,
        planAmount: plan.price,
        plan,
      });
      if (response.status === 200) {
        const { orderId, amount, currency, id } = response.data;
        setLoading(false);
        // Trigger Razorpay Checkout
        handleRazorpayPayment(orderId, amount, currency);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error creating order:", error);
      setLoading(false);
    }
  };
  if (isThankYouPage) {
    return <Thankupage />;
  }

  const handleRazorpayPayment = (orderId, amount, currency, membershipid) => {
    const options = {
      key: "rzp_test_LNhYosjgDmTZAH", // Replace with Razorpay Key ID
      amount, // Amount in paise
      currency,
      name: plan.plan,
      description: "Membership Checkout",
      order_id: orderId,
      handler: async function (response) {
        try {
          const verifyResponse = await axios.post("/api/verify-payment", {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          });

          if (verifyResponse.status === 200) {
            toastRef.current.addToast("Payment Sucessfull", "success");
            localStorage.setItem("recipientid", "receipt_" + membershipid);
            setIsThankYouPage(true); // Show Thank You page after verification
          } else {
            toastRef.current.addToast(
              "Payment verification failed. Please contact support",
              "error"
            );
          }
        } catch (error) {
          toastRef.current.addToast(
            "Payment verification failed. Please contact support",
            "error"
          );
        }
      },
      prefill: {
        name: `${formData.firstname} ${formData.lastname}`,
        email: formData.email,
        contact: formData.phone,
      },
      theme: {
        color: "#3399cc",
      },
    };

    const razorpay = new Razorpay(options);
    razorpay.open();
  };

  // const handleRazorpayPayment = (orderId, amount, currency) => {
  //   if (!window.isRazorpayLoaded || typeof Razorpay === "undefined") {
  //     // alert("Razorpay SDK is not loaded. Please try again later.");
  //     return;
  //   }

  //   const options = {
  //     key: "rzp_test_LNhYosjgDmTZAH", // Razorpay Key ID
  //     amount: amount, // Amount in paise
  //     currency: currency,
  //     name: "Pro Care Membership",
  //     description: "Membership Checkout",
  //     order_id: orderId,
  //     handler: async function (response) {
  //       try {
  //         const verifyResponse = await axios.post("/api/verify-payment", {
  //           razorpay_order_id: response.razorpay_order_id,
  //           razorpay_payment_id: response.razorpay_payment_id,
  //           razorpay_signature: response.razorpay_signature,
  //         });

  //         if (verifyResponse.status === 200) {
  //           setIsThankYouPage(true);
  //         } else {
  //           alert("Payment verification failed. Please contact support.");
  //         }
  //       } catch (error) {
  //         console.error("Payment verification error:", error);
  //       }
  //     },
  //     prefill: {
  //       name: `${formData.firstname} ${formData.lastname}`,
  //       email: formData.email,
  //       contact: formData.phone,
  //     },
  //     theme: {
  //       color: "#3399cc",
  //     },
  //   };

  //   const razorpay = new Razorpay(options);
  //   razorpay.open();
  // };
  return (
    <>
      <section className="main-wordpess-section-thankyou main-from-section-style">
        <div
          className={`checkout-WpmantinanceLogo-style ${
            isVisible ? "animate-slide" : ""
          }`}
          ref={logoRef}
        >
          <div
            className={`thankyou-WpmantinanceLogo-style-inner ${expand ? "expand" : ""}`}
            onClick={() => setExpand(true)}
          >
            {" "}
            <img
              src={WpmantinanceLogo}
              alt="company logo"
              height="100%"
              width="100%"
              className="cursor-pointer"
              onClick={(e) => navigate("/services/wordpress-maintenance")}
            ></img>
          </div>
        </div>
        <div className="container m-auto py-20 ">
          <div className=" items-center py-8 w-full">
            <div className="Membership-chackout">
              <h1 className=" mb-4 text-white">
                <span className="checkoutmembership">Membership</span>{" "}
                Information
              </h1>
            </div>
            <div className="flex items-center justify-center ">
              <div className="p-6 text-center text-white">
                <h2 className="text-xl font-semibold mb-2">
                  You have selected the ${plan?.discountedPrice.toFixed(2)}{" "}
                  membership level.
                </h2>
                <p className="text-white-700 mb-6">
                  The price for membership is{" "}
                  <strong> ${plan?.discountedPrice.toFixed(2)}</strong> per{" "}
                  {plan?.planFrequency?.toLowerCase()} for{" "}
                  {plan?.numberOfWebsites} website
                  {plan?.numberOfWebsites > 1 ? "s" : ""}.
                </p>
              </div>
            </div>

            <div className="xl:w-full lg:flex justify-between xl:mx-0 md:mx-20 mx-10">
              <div className="checkoutbg ">
                <img src={checkoutbgImage} alt="Checkout Background" />
              </div>
              <div className="membershipform">
                <div>
                  {" "}
                  <div className="border-2 rounded-lg p-6  shadow-md selectedplan">
                    <div className="sm:flex justify-between  items-center w-full ">
                      <div className="flex items-center justify-between sm:gap-8">
                        <div
                          className="w-16 h-16  rounded-full flex items-center justify-center"
                          style={{ backgroundColor: plan?.strokeColor }}
                        >
                          <span className="text-white text-2xl font-bold">
                            {plan?.plan?.charAt(0)}
                          </span>
                        </div>
                        <div className="">
                          <h2
                            className="text-2xl font-bold  "
                            style={{ color: plan?.strokeColor }}
                          >
                            {plan?.plan}
                          </h2>
                          <p className="text-lg font-medium">
                            ${plan?.discountedPrice.toFixed(2)} /{" "}
                            <span
                              className="original-price"
                              style={{
                                color: "#fff",
                                textDecoration: "line-through",
                                fontSize: "14px",
                                textDecorationColor: "red",
                                textDecorationThickness: "2px",
                              }}
                            >
                              {" "}
                              ${plan?.totalCost.toFixed(2)}
                            </span>
                          </p>
                          <p className="text-sm font-medium">
                            Service Frequency:
                            {plan.serviceFrequency.charAt(0).toUpperCase() +
                              plan.serviceFrequency.slice(1)}{" "}
                          </p>
                          <p className="text-sm font-medium">
                            Payment Frequency:{" "}
                            {plan.paymentFrequency.charAt(0).toUpperCase() +
                              plan.paymentFrequency.slice(1)}
                          </p>
                          <p className="text-sm font-medium">
                            Covers {plan?.numberOfWebsites}{" "}
                            {plan?.numberOfWebsites > 1
                              ? "websites"
                              : "website"}{" "}
                          </p>
                        </div>
                      </div>
                      <div className="wp-Edit-btn">
                        <button onClick={HandleClick}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width={20}
                            fill="#fff"
                          >
                            <path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152L0 424c0 48.6 39.4 88 88 88l272 0c48.6 0 88-39.4 88-88l0-112c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 112c0 22.1-17.9 40-40 40L88 464c-22.1 0-40-17.9-40-40l0-272c0-22.1 17.9-40 40-40l112 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L88 64z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <h1 className="text-white text-2xl mt-6 mb-4 pb-2">
                  Account Information
                </h1>
                {isLoginMode ? (
                  <div className=" ">
                    <form onSubmit={handleLoginSubmit}>
                      <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">
                          Email Address <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={loginData.email}
                          onChange={handleLoginChange}
                          className="w-full p-2 border rounded-lg"
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">
                          Password <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="password"
                          name="password"
                          value={loginData.password}
                          onChange={handleLoginChange}
                          className="w-full p-2 border rounded-lg"
                          required
                        />
                      </div>
                      <button
                        type="submit"
                        className="w-48 px-6 text-white py-2 cursor-pointer"
                      >
                        Log In
                      </button>
                    </form>
                    <p className="text-white mt-4">
                      Don't have an account?{" "}
                      <span
                        className="cursor-pointer text-red-500"
                        onClick={() => setIsLoginMode(false)}
                      >
                        Register here
                      </span>
                    </p>
                  </div>
                ) : (
                  <form
                    className=" input-form-style selectedplan  rounded-lg p-6  shadow-md"
                    onSubmit={handleSubmit}
                  >
                    <div className="xl:grid xl:grid-cols-2 xl:gap-x-4 xl:gap-y-2">
                      {" "}
                      <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">
                          First Name{" "}
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          name="firstname"
                          value={formData.firstname}
                          onChange={handleInputChange}
                          className="w-full p-2 border rounded-lg"
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">
                          Last Name{" "}
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          name="lastname"
                          value={formData.lastname}
                          onChange={handleInputChange}
                          className="w-full p-2 border rounded-lg"
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">
                          Email Address{" "}
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          className="w-full p-2 border rounded-lg"
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">
                          Phone{" "}
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <div>
                          <PhoneInput
                            country={countryCode} // Set default country
                            value={formData.phone}
                            onChange={(value) =>
                              handleChange({ target: { name: "phone", value } })
                            }
                            inputProps={{
                              name: "phone",
                              required: true,
                              placeholder: "Enter Mobile Number",
                            }}
                            enableSearch // Optional: Adds a search bar for countries
                          />
                          {errors.phone && (
                            <p className="error">{errors.phone}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="checkout-submit-btn mt-2">
                      {" "}
                      <button
                        type="submit"
                        className=" px-6 text-white py-2 rounded-lg cursor-pointer"
                      >
                        Proceed
                      </button>
                    </div>
                    <div className="text-white mt-2 ">
                      Already have an account?
                      <span
                        className="cursor-pointer"
                        style={{
                          color: "red",
                        }}
                        // onClick={() => setIsLoginMode(true)}
                        onClick={() => navigate("/login")}
                      >
                         Log in here
                      </span>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </>
  );
};

export default CheckoutPage;

import React, { useState } from "react";
import copy from "copy-to-clipboard";
import Helmet from "react-helmet";
import logo from "../../../img/logo192.png";
function UrlDecoder() {
  const [encodedUrl, setEncodedUrl] = useState("");
  const [decodedUrl, setDecodedUrl] = useState("");

  const handleDecode = () => {
    try {
      // Decode the URL
      const decoded = decodeURIComponent(encodedUrl);
      setDecodedUrl(decoded);
    } catch (error) {
      console.error("Error decoding URL:", error);
      setDecodedUrl("Error decoding URL");
    }
  };

  const handleCopyToClipboard = () => {
    if (decodedUrl) {
      copy(decodedUrl);
      // Optionally, you can provide some feedback to the user
      // e.g., set a message that fades out after a few seconds
    }
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="" />
        <meta
          name="title"
          content="Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" coding validation tools, programming tools, code checker, code error finding tool, code beautify tool, code scanning tool"
        />
        <meta
          name="description"
          content=" Discover top coding validation tools to ensure error-free, high-quality code. Learn how tools like HTML Preview, Minyfiy to UnMinyfiy, Json Validator, Validate CSS, String Replace, Webhook Test can streamline your development workflow and enforce coding standards. "
        />
        {/* <link rel="apple-touch-icon" href={logo} /> */}
        <title>
          Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech
        </title>
      </Helmet>

      <div className="max-w-2xl mx-auto p-6 bg-white border rounded-lg m-16">
        <div className="p-2 ">
          <h2 className="text-2xl font-bold mb-4">URL Decoder</h2>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Encoded URL:</label>
            <input
              type="text"
              value={encodedUrl}
              onChange={(e) => setEncodedUrl(e.target.value)}
              placeholder="Enter encoded URL..."
              className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
            />
          </div>
          <button
            onClick={handleDecode}
            className="bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 mr-2"
          >
            Decode URL
          </button>
          <button
            onClick={handleCopyToClipboard}
            disabled={!decodedUrl}
            className={`bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600 ${!decodedUrl ? "opacity-50 cursor-not-allowed" : ""}`}
          >
            Copy to Clipboard
          </button>
          <div className="mt-4">
            <label className="block text-sm font-bold mb-2">Decoded URL:</label>
            <textarea
              value={decodedUrl}
              readOnly
              placeholder="Decoded URL will appear here..."
              className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
              rows="3"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default UrlDecoder;
